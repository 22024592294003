import { post, get } from 'helpers/api_helper';
import React, { useState, useEffect, useRef } from 'react'
import * as url from "../../helpers/url_helper"
import { Alert, Button } from 'reactstrap';
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const AddTBA = (props) => {
    document.title = "Add TBA"

    const breadcrumbItems = [
        { title: "TBA", link: "#" },
        { title: "Add", link: "#" }
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Add TBA', breadcrumbItems)
    }, [])

    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [username, setUsername] = useState('');
    const formRef = useRef(null);

    const validationSchema = Yup.object().shape({
        role: Yup.string().required('Role is required'),
        email: Yup.string().email('Invalid email').required('Email is required'),
        password: Yup.string().required('Password is required'),
        name: Yup.string().required('Name is required'),
        contactNumber: Yup.string().required('Contact Number is required'),
        // Add other validations as needed
    });

    const handleRoleChange = async (setFieldValue, value) => {
        setFieldValue('role', value);
        try {
            const response = await post(`${url.GET_COUNT_BY_ROLE}/${value}`);
            const count = response.count + 1;
            let newUsername;

            if (value === 'admin' || value === 'superadmin') {
                newUsername = `${value}${count}`;
            } else if (value === 'tba') {
                newUsername = `TBA${1000 + count}`;
            } else if (value === 'staff') {
                newUsername = `STT${1000 + count}`;
            }

            setFieldValue('username', newUsername);
        } catch (error) {
            console.error('Error fetching username:', error);
        }
    };

    const incrementUsername = (lastUsername) => {
        const numPart = parseInt(lastUsername.replace(/^\D+/g, ''));
        const newNum = numPart + 1;
        return lastUsername.replace(/\d+$/, newNum.toString().padStart(4, '0'));
    };

    const handleImageUpload = async (file, setFieldValue, fieldName) => {
        if (!file) {
            console.error('No file selected');
            return;
        }

        if (file.size > 5 * 1024 * 1024) {
            alert('File size should be less than 5MB');
            return;
        }

        const formData = new FormData();
        formData.append('image', file);

        try {
            const response = await post(url.UPLOAD_IMAGE, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            if (response.filename) {
                setFieldValue(`${fieldName}`, response.filename);
                console.log(`File uploaded successfully: ${response.filename}`);
            } else {
                console.error('Upload response does not contain filename:', response);
            }
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    };

    const initialValues = {
        role: '',
        username: '',
        email: '',
        password: '',
        name: '',
        photo: '',
        contactNumber: '',
        postalAddress: '',
        landmark: '',
        city: '',
        state: '',
        pincode: '',
        aadharCardFront: '',
        aadharCardBack: '',
        panCard: '',
        bankName: '',
        accountNumber: '',
        ifscCode: '',
        branch: '',
        UPI: ''
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    return (
        <React.Fragment>
            <div className="mt-5 mt-lg-4" ref={formRef}>
                <h5 className="font-size-14 mb-4">
                    <i className="mdi mdi-arrow-right text-primary me-1"></i>
                    Add TBA credentials
                </h5>

                {success && <Alert color="success">{success}</Alert>}
                {error && <Alert color="danger">{error}</Alert>}

                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                        setError('');
                        setSuccess('');
                        try {
                            const response = await post(url.ADD_TBA, values);
                            if (response.user) {
                                setSuccess(response.message);
                                resetForm();
                                console.log("User created:", response.user);
                            } else {
                                setError("Failed to create user. Please try again.");
                            }
                        } catch (error) {
                            if (error.response && error.response.data && error.response.data.message) {
                                setError(error.response.data.message);
                            } else {
                                setError("An unexpected error occurred. Please try again.");
                            }
                            console.error("Error creating user:", error);
                        }
                        setSubmitting(false);
                        scrollToTop();
                    }}
                >
                    {({ isSubmitting, setFieldValue, values }) => (
                        <Form>
                            <div className="row mb-4">
                                <label htmlFor="role" className="col-sm-3 col-form-label"><span className="text-danger">*</span>Role</label>
                                <div className="col-sm-9">
                                    <Field as="select" className="form-control" id="role" name="role" onChange={(e) => handleRoleChange(setFieldValue, e.target.value)}>
                                        <option value="">Select</option>
                                        <option value="admin">Admin</option>
                                        <option value="superadmin">Superadmin</option>
                                        <option value="tba">TBA</option>
                                        <option value="staff">Staff</option>
                                    </Field>
                                    <ErrorMessage name="role" component="div" className="text-danger" />
                                </div>
                            </div>
                            <div className="row mb-4">
                                <label htmlFor="username" className="col-sm-3 col-form-label">Username</label>
                                <div className="col-sm-9">
                                    <Field type="text" className="form-control" id="username" name="username" readOnly />
                                </div>
                            </div>
                            <div className="row mb-4">
                                <label htmlFor="email" className="col-sm-3 col-form-label"><span className="text-danger">*</span>Email</label>
                                <div className="col-sm-9">
                                    <Field type="email" className="form-control" id="email" name="email" />
                                    <ErrorMessage name="email" component="div" className="text-danger" />
                                </div>
                            </div>
                            <div className="row mb-4">
                                <label htmlFor="password" className="col-sm-3 col-form-label"><span className="text-danger">*</span>Password</label>
                                <div className="col-sm-9">
                                    <Field type="password" className="form-control" id="password" name="password" />
                                    <ErrorMessage name="password" component="div" className="text-danger" />
                                </div>
                            </div>
                            <div className="row mb-4">
                                <label htmlFor="name" className="col-sm-3 col-form-label"><span className="text-danger">*</span>Name</label>
                                <div className="col-sm-9">
                                    <Field type="text" className="form-control" id="name" name="name" />
                                    <ErrorMessage name="name" component="div" className="text-danger" />
                                </div>
                            </div>
                            <div className="row mb-4">
                                <label htmlFor="photo" className="col-sm-3 col-form-label">Photo</label>
                                <div className="col-sm-9">
                                    <div className="d-flex">
                                        <input
                                            type="file"
                                            id="photo"
                                            onChange={(event) => {
                                                setFieldValue('photoFile', event.currentTarget.files[0]);
                                            }}
                                            accept="image/*"
                                            className="form-control"
                                        />
                                        <Button
                                            color="primary"
                                            onClick={() => handleImageUpload(values.photoFile, setFieldValue, 'photo')}
                                            className="ms-2"
                                        >
                                            Upload
                                        </Button>
                                    </div>
                                    {values.photo && (
                                        <div className="mt-2">
                                            <p>File uploaded: {values.photo}</p>
                                            <img
                                                src={`${process.env.REACT_APP_API_URL}/image/files/${values.photo}`}
                                                alt="Uploaded photo"
                                                style={{ maxWidth: '200px', maxHeight: '200px' }}

                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="row mb-4">
                                <label htmlFor="contactNumber" className="col-sm-3 col-form-label"><span className="text-danger">*</span>Contact Number</label>
                                <div className="col-sm-9">
                                    <Field
                                        type="text"
                                        className="form-control"
                                        id="contactNumber"
                                        name="contactNumber"
                                        maxLength={10}
                                        onKeyPress={(e) => {
                                            const charCode = e.which ? e.which : e.keyCode;
                                            if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                    <ErrorMessage name="contactNumber" component="div" className="text-danger" />
                                </div>
                            </div>
                            <div className="row mb-4">
                                <label htmlFor="postalAddress" className="col-sm-3 col-form-label">Postal Address</label>
                                <div className="col-sm-9">
                                    <Field type="text" className="form-control" id="postalAddress" name="postalAddress" />
                                </div>
                            </div>
                            <div className="row mb-4">
                                <label htmlFor="landmark" className="col-sm-3 col-form-label">Landmark</label>
                                <div className="col-sm-9">
                                    <Field type="text" className="form-control" id="landmark" name="landmark" />
                                </div>
                            </div>
                            <div className="row mb-4">
                                <label htmlFor="city" className="col-sm-3 col-form-label">City</label>
                                <div className="col-sm-9">
                                    <Field type="text" className="form-control" id="city" name="city" />
                                </div>
                            </div>
                            <div className="row mb-4">
                                <label htmlFor="state" className="col-sm-3 col-form-label">State</label>
                                <div className="col-sm-9">
                                    <Field type="text" className="form-control" id="state" name="state" />
                                </div>
                            </div>
                            <div className="row mb-4">
                                <label htmlFor="pincode" className="col-sm-3 col-form-label">Pincode</label>
                                <div className="col-sm-9">
                                    <Field type="text" className="form-control" id="pincode" name="pincode" />
                                </div>
                            </div>
                            <div className="row mb-4">
                                <label htmlFor="aadharCardFront" className="col-sm-3 col-form-label">Aadhar Card Front</label>
                                <div className="col-sm-9">
                                    <div className="d-flex">
                                        <input type="file" id="aadharCardFront" onChange={(event) => {
                                            setFieldValue('aadharCardFrontFile', event.currentTarget.files[0]);
                                        }} accept="image/*" className="form-control" />
                                        <Button color="primary" onClick={() => handleImageUpload(values.aadharCardFrontFile, setFieldValue, 'aadharCardFront')} className="ms-2">
                                            Upload
                                        </Button>
                                    </div>
                                    {values.aadharCardFront && (
                                        <div className="mt-2">
                                            <p>File uploaded: {values.aadharCardFront}</p>
                                            <img
                                                src={`${process.env.REACT_APP_API_URL}/image/files/${values.aadharCardFront}`}
                                                alt="Aadhar Card Front"
                                                style={{ maxWidth: '200px', maxHeight: '200px' }}
                                                crossOrigin="anonymous"
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="row mb-4">
                                <label htmlFor="aadharCardBack" className="col-sm-3 col-form-label">Aadhar Card Back</label>
                                <div className="col-sm-9">
                                    <div className="d-flex">
                                        <input type="file" id="aadharCardBack" onChange={(event) => {
                                            setFieldValue('aadharCardBackFile', event.currentTarget.files[0]);
                                        }} accept="image/*" className="form-control" />
                                        <Button color="primary" onClick={() => handleImageUpload(values.aadharCardBackFile, setFieldValue, 'aadharCardBack')} className="ms-2">
                                            Upload
                                        </Button>
                                    </div>
                                    {values.aadharCardBack && (
                                        <div className="mt-2">
                                            <p>File uploaded: {values.aadharCardBack}</p>
                                            <img
                                                src={`${process.env.REACT_APP_API_URL}/image/files/${values.aadharCardBack}`}
                                                alt="Aadhar Card Back"
                                                style={{ maxWidth: '200px', maxHeight: '200px' }}
                                                crossOrigin="anonymous"
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="row mb-4">
                                <label htmlFor="panCard" className="col-sm-3 col-form-label">PAN Card</label>
                                <div className="col-sm-9">
                                    <div className="d-flex">
                                        <input type="file" id="panCard" onChange={(event) => {
                                            setFieldValue('panCardFile', event.currentTarget.files[0]);
                                        }} accept="image/*" className="form-control" />
                                        <Button color="primary" onClick={() => handleImageUpload(values.panCardFile, setFieldValue, 'panCard')} className="ms-2">
                                            Upload
                                        </Button>
                                    </div>
                                    {values.panCard && (
                                        <div className="mt-2">
                                            <p>File uploaded: {values.panCard}</p>
                                            <img
                                                src={`${process.env.REACT_APP_API_URL}/image/files/${values.panCard}`}
                                                alt="PAN Card"
                                                style={{ maxWidth: '200px', maxHeight: '200px' }}
                                                crossOrigin="anonymous"
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="row mb-4">
                                <label htmlFor="bankName" className="col-sm-3 col-form-label">Bank Name</label>
                                <div className="col-sm-9">
                                    <Field type="text" className="form-control" id="bankName" name="bankName" />
                                </div>
                            </div>
                            <div className="row mb-4">
                                <label htmlFor="accountNumber" className="col-sm-3 col-form-label">Account Number</label>
                                <div className="col-sm-9">
                                    <Field type="text" className="form-control" id="accountNumber" name="accountNumber" />
                                </div>
                            </div>
                            <div className="row mb-4">
                                <label htmlFor="ifscCode" className="col-sm-3 col-form-label">IFSC Code</label>
                                <div className="col-sm-9">
                                    <Field type="text" className="form-control" id="ifscCode" name="ifscCode" />
                                </div>
                            </div>
                            <div className="row mb-4">
                                <label htmlFor="branch" className="col-sm-3 col-form-label">Branch</label>
                                <div className="col-sm-9">
                                    <Field type="text" className="form-control" id="branch" name="branch" />
                                </div>
                            </div>
                            <div className="row mb-4">
                                <label htmlFor="UPI" className="col-sm-3 col-form-label">UPI</label>
                                <div className="col-sm-9">
                                    <Field type="text" className="form-control" id="UPI" name="UPI" />
                                </div>
                            </div>
                            <div className="row justify-content-end">
                                <div className="col-sm-9">
                                    <div>
                                        <button type="submit" className="btn btn-primary w-lg" disabled={isSubmitting}>
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </React.Fragment>
    )
}

export default connect(null, { setBreadcrumbItems })(AddTBA)