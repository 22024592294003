import React from "react"
import { Card, CardBody, Row, CardTitle } from "reactstrap"
import { getCurrentUser } from "../../helpers/url_helper"

const MonthlyEarnings = ({ monthlyRevenue, name }) => {
    const currentUser = getCurrentUser()
    const isAdmin = currentUser.role === 'admin'

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">{name || `Monthly ${isAdmin ? 'Earnings' : 'Commission'}`}</CardTitle>

                    <Row className="text-center mt-4">
                        <div className="col-12">
                            <h5 className="font-size-20">{monthlyRevenue.toFixed(2)} ₹</h5>
                            <p className="text-muted">
                                Total {name || `Monthly ${isAdmin ? 'Income' : 'Commission'}`}
                            </p>
                        </div>
                    </Row>
                  

                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default MonthlyEarnings
