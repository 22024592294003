import PropTypes from 'prop-types'
import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import logo from "../../assets/images/logo-sm.png"
import logoLightPng from "../../assets/images/b_bandhu.png"
import logoDark from "../../assets/images/logo-dark.png"
import { withTranslation } from "react-i18next"
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions"
import { isAdmin } from 'helpers/url_helper'
import { get, post } from 'helpers/api_helper'
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap'
import * as url from "../../helpers/url_helper"

const Header = props => {
  const [isLeadRequested, setIsLeadRequested] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [notification, setNotification] = useState('');

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    var body = document.body;
    body.classList.toggle("vertical-collpsed");
    body.classList.toggle("sidebar-enable");
  }



  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex align-items-center">
            <div className="navbar-brand-box">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoLightPng} alt="gamwe" width="80" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logo} alt="" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={logoLightPng} alt=""  width="80"  />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={tToggle}
              className="btn btn-sm px-3 font-size-24 header-item waves-effect vertical-menu-btn d-flex align-items-center justify-content-center"
              id="vertical-menu-btn"
            >
              <i className="mdi mdi-menu"></i>
            </button>
          </div>
          <div className="d-flex align-items-center">
            <div className="dropdown d-none d-lg-inline-block">
              <button
                type="button"
                onClick={toggleFullscreen}
                className="btn header-item noti-icon waves-effect d-flex align-items-center justify-content-center"
                data-toggle="fullscreen"
              >
                <i className="mdi mdi-fullscreen font-size-24"></i>
              </button>
            </div>
            <div className="dropdown d-none d-lg-inline-block">
              <button
                type="button"
                className="btn header-item noti-icon waves-effect d-flex align-items-center justify-content-center"
                data-toggle="fullscreen"
              >
                <Link to="/renewals" className="waves-effect d-flex align-items-center justify-content-center position-relative">
                  <i className="mdi mdi-repeat"></i>
                  <span className="position-absolute top-0 start-100 translate-middle p-1 bg-danger border border-light rounded-circle" style={{ width: '12px', height: '12px', transform: 'translate(-50%, -50%)' }}>
                    <span className="visually-hidden">New alerts</span>
                  </span>
                </Link>
              </button>
            </div>

           
            <ProfileMenu />
          </div>
        </div>
      </header>


      {notification && (
        <div className="alert alert-info" role="alert">
          {notification}
        </div>
      )}
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
}

const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
  } = state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header))
