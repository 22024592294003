import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card, CardBody, Label, Form, Alert, Input, FormFeedback, InputGroup, InputGroupText } from 'reactstrap';
import logoDark from "../../assets/images/b_bandhu.png";
import logoLight from "../../assets/images/b_bandhu.png";
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import PropTypes from "prop-types";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
import withRouter from 'components/Common/withRouter';

// actions
import { loginUser, socialLogin } from "../../store/actions";

const Login = props => {

  const dispatch = useDispatch();
  const [loginError, setLoginError] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const validation = useFormik({
    enableReinitialize: false, // Changed to false to prevent re-rendering
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: async (values) => {
      if (values && values.email && values.password) {
        try {
          await dispatch(loginUser(values, props.router.navigate));
        } catch (error) {
          console.log(error);
          setLoginError(error || "Login failed. Please try again.");
        }
      } else {
        setLoginError("Invalid form data. Please check your inputs.");
      }
    }
  });

  const selectLoginState = (state) => state.Login;
  const LoginProperties = createSelector(
    selectLoginState,
    (login) => ({
      error: login.error
    })
  );

  const {
    error
  } = useSelector(LoginProperties);

  useEffect(() => {
    document.title = "Login | TBA Panel";
  }, []); // Title set only once on mount

  useEffect(() => {
    if (error && error !== loginError) { // Only update if error is different
      setLoginError(error?.message || error);
    }
  }, [error]);

  const signIn = type => {
    dispatch(socialLogin(type, props.router.navigate));
  };

  const socialResponse = type => {
    signIn(type);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-5 mb-4">
                    <Link to="/" className="d-block auth-logo">
                      <img src={logoDark} alt="" height="200"  className="auth-logo-dark" />
                    </Link>
                  </h3>
                  <div className="p-3">
                    <h4 className="text-muted font-size-18 mb-1 text-center">Welcome Back !</h4>
                    <Form
                      className="form-horizontal mt-4"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {loginError && <Alert color="danger">{loginError}</Alert>}
                      <div className="mb-3">
                        <Label htmlFor="username">Username</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values?.email || ""}
                          invalid={
                            validation.touched?.email && validation.errors?.email ? true : false
                          }
                        />
                        {validation.touched?.email && validation.errors?.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <Label htmlFor="userpassword">Password</Label>
                        <InputGroup>
                          <Input
                            name="password"
                            value={validation.values?.password || ""}
                            type={showPassword ? "text" : "password"}
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched?.password && validation.errors?.password ? true : false
                            }
                          />
                          <InputGroupText style={{cursor: 'pointer'}} onClick={togglePasswordVisibility}>
                            <i className={`mdi ${showPassword ? 'mdi-eye-off' : 'mdi-eye'}`}></i>
                          </InputGroupText>
                          {validation.touched?.password && validation.errors?.password ? (
                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                          ) : null}
                        </InputGroup>
                      </div>
                      <div className="mb-3 text-end">
                        <Link to="/forgot-password" className="text-muted">Forgot password?</Link>
                      </div>
                      <Row className="mb-3 mt-4 justify-content-center">
                        <div className="col-6 text-center">
                          <button className="btn btn-primary w-md waves-effect waves-light" type="submit">Log In</button>
                        </div>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                © {new Date().getFullYear()}  <span className="d-none d-sm-inline-block"> - Crafted with <i className="mdi mdi-heart text-danger"></i> by Agevole.</span>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
