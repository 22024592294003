import React, { useState } from "react"
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Button, Alert, InputGroup, InputGroupText } from "reactstrap"
import { post } from "helpers/api_helper"
import * as url from "helpers/url_helper"

const ChangePassword = () => {
    document.title = "Change Password"

    const [currentPassword, setCurrentPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [error, setError] = useState("")
    const [success, setSuccess] = useState("")
    const [loading, setLoading] = useState(false)
    const [showCurrentPassword, setShowCurrentPassword] = useState(false)
    const [showNewPassword, setShowNewPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)

    const toggleCurrentPasswordVisibility = () => {
        setShowCurrentPassword(!showCurrentPassword)
    }

    const toggleNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword)
    }

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setError("")
        setSuccess("")

        // Validation
        if (!currentPassword || !newPassword || !confirmPassword) {
            setError("All fields are required")
            return
        }

        if (newPassword !== confirmPassword) {
            setError("New passwords do not match")
            return
        }

        if (newPassword.length < 6) {
            setError("Password must be at least 6 characters long")
            return
        }

        try {
            setLoading(true)
            const response = await post(url.CHANGE_PASSWORD, {
                currentPassword: currentPassword,
                newPassword: newPassword,
            })

            if (response.code === 200) {
                setSuccess("Password changed successfully!")
                // Reset form
                setCurrentPassword("")
                setNewPassword("")
                setConfirmPassword("")
            } else {
                setError(response.message || "Failed to change password")
            }
        } catch (err) {
            setError(err.response?.data?.message || err.message || "An error occurred while changing password")
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="page-content">
            <Container fluid>
                <Row className="justify-content-center">
                    <Col md={8} lg={6} xl={5}>
                        <Card className="overflow-hidden">
                            <CardBody className="p-4">
                                <div className="p-3">
                                    <h4 className="text-primary mb-4">Change Password</h4>

                                    {error && <Alert color="danger">{error}</Alert>}
                                    {success && <Alert color="success">{success}</Alert>}

                                    <Form onSubmit={handleSubmit}>
                                        <FormGroup className="mb-3">
                                            <Label for="currentPassword">Current Password</Label>
                                            <InputGroup>
                                                <Input
                                                    type={showCurrentPassword ? "text" : "password"}
                                                    id="currentPassword"
                                                    value={currentPassword}
                                                    onChange={(e) => setCurrentPassword(e.target.value)}
                                                    placeholder="Enter current password"
                                                />
                                                <InputGroupText style={{cursor: 'pointer'}} onClick={toggleCurrentPasswordVisibility}>
                                                    <i className={`mdi ${showCurrentPassword ? 'mdi-eye-off' : 'mdi-eye'}`}></i>
                                                </InputGroupText>
                                            </InputGroup>
                                        </FormGroup>

                                        <FormGroup className="mb-3">
                                            <Label for="newPassword">New Password</Label>
                                            <InputGroup>
                                                <Input
                                                    type={showNewPassword ? "text" : "password"}
                                                    id="newPassword"
                                                    value={newPassword}
                                                    onChange={(e) => setNewPassword(e.target.value)}
                                                    placeholder="Enter new password"
                                                />
                                                <InputGroupText style={{cursor: 'pointer'}} onClick={toggleNewPasswordVisibility}>
                                                    <i className={`mdi ${showNewPassword ? 'mdi-eye-off' : 'mdi-eye'}`}></i>
                                                </InputGroupText>
                                            </InputGroup>
                                        </FormGroup>

                                        <FormGroup className="mb-4">
                                            <Label for="confirmPassword">Confirm New Password</Label>
                                            <InputGroup>
                                                <Input
                                                    type={showConfirmPassword ? "text" : "password"}
                                                    id="confirmPassword"
                                                    value={confirmPassword}
                                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                                    placeholder="Confirm new password"
                                                />
                                                <InputGroupText style={{cursor: 'pointer'}} onClick={toggleConfirmPasswordVisibility}>
                                                    <i className={`mdi ${showConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'}`}></i>
                                                </InputGroupText>
                                            </InputGroup>
                                        </FormGroup>

                                        <div className="d-grid">
                                            <Button
                                                color="primary"
                                                type="submit"
                                                disabled={loading}
                                            >
                                                {loading ? "Changing..." : "Change Password"}
                                            </Button>
                                        </div>
                                    </Form>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ChangePassword