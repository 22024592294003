import React, { useState, useEffect, useRef } from "react"
import {
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Table,
    Alert,
    Row,
    Col,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap"
import { get, post } from "helpers/api_helper"
import * as url from "../../helpers/url_helper"
import {
    getAuth,
    signInWithEmailAndPassword,
    createUserWithEmailAndPassword,
} from "firebase/auth"
import {
    getFirestore,
    doc,
    getDoc,
    setDoc,
    updateDoc,
    increment,
    collection,
    getDocs,
    query,
    where,
} from "firebase/firestore"
import { app } from "../../firebase"
import QRCode from "./QR.jpeg"
import classnames from "classnames"

const auth = getAuth(app)
const db = getFirestore(app)

const OrderVerification = () => {
    document.title = "Order Confirmation"
    const db = getFirestore()
    const topRef = useRef(null)

    const scrollToTop = () => {
        topRef.current?.scrollIntoView({ behavior: "smooth" })
    }

    const [orderId, setOrderId] = useState("")
    const [orderDetails, setOrderDetails] = useState(null)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [retypePassword, setRetypePassword] = useState("")
    const [paymentMethod, setPaymentMethod] = useState("")
    const [transactionId, setTransactionId] = useState("")
    const [error, setError] = useState("")
    const [success, setSuccess] = useState("")
    const [userExists, setUserExists] = useState(false)
    const [name, setName] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [username, setUsername] = useState("")
    const [aadharDetails, setAadharDetails] = useState("")
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [activeTab, setActiveTab] = useState("1")
    const [usernameAvailable, setUsernameAvailable] = useState(null)
    const [aadharAvailable, setAadharAvailable] = useState(null)
    const [isReadOnly, setIsReadOnly] = useState(false)

    const handleOrderIdChange = e => setOrderId(e.target.value)
    const handleEmailChange = e => setEmail(e.target.value)
    const handlePasswordChange = e => setPassword(e.target.value)
    const handleRetypePasswordChange = e => setRetypePassword(e.target.value)
    const handleNameChange = e => setName(e.target.value)
    const handlePhoneNumberChange = e => setPhoneNumber(e.target.value)
    const handleUsernameChange = e => setUsername(e.target.value)
    const handleAadharDetailsChange = e => setAadharDetails(e.target.value)
    const handlePaymentMethodChange = e => setPaymentMethod(e.target.value)
    const handleTransactionIdChange = e => setTransactionId(e.target.value)

    const toggleTab = tab => {
        if (activeTab !== tab) setActiveTab(tab)
    }

    const fetchOrderDetails = async () => {
        try {
            const response = await get(
                `${url.GET_ORDER_DETAILS_BY_UNIQUE_TAG}/${orderId}`,
            )
            console.log("Order details fetched:", response)
            setOrderDetails(response)
            setEmail(response.email)
            setName(response.leadName)
            setPhoneNumber(response.phoneNumber)
            const userExistsResult = await checkUserExists(response.email)
            console.log("User exists check result:", userExistsResult)
            setUserExists(userExistsResult)
        } catch (error) {
            console.error("Error fetching order details:", error)
            setError(
                error.response?.data?.message ||
                error.message ||
                "Failed to fetch order details",
            )
            scrollToTop()
        }
    }

    const checkUserExists = async email => {
        const userCollectionRef = collection(db, "users")
        const q = query(userCollectionRef, where("email", "==", email))

        try {
            const querySnapshot = await getDocs(q)
            console.log("User existence check query result:", !querySnapshot.empty)
            return !querySnapshot.empty
        } catch (error) {
            console.error("Error checking email existence:", error)
            return false
        }
    }

    const handleLogin = async () => {
        try {
            setError("")
            setSuccess("")

            if (!email || !password) {
                setError("Email and password are required.")
                scrollToTop()
                return
            }

            console.log("Attempting login for email:", email)
            const userCredential = await signInWithEmailAndPassword(
                auth,
                email,
                password,
            )
            const user = userCredential.user

            // Get user document after login
            const userDocRef = doc(db, "users", email)
            const userDoc = await getDoc(userDocRef)
            console.log("User document:", userDoc.data())

            if (userDoc.exists()) {
                const userData = userDoc.data()
                if (userData.username && userData.aadhar_number) {
                    setUsername(userData.username)
                    setAadharDetails(userData.aadhar_number)
                    setIsReadOnly(true) // Set fields as readonly if user exists
                }
            }

            console.log("Login successful for user:", user.uid)

            setIsLoggedIn(true)
            setSuccess("Successfully logged in!")
            scrollToTop()
        } catch (error) {
            console.error("Error in login:", error)
            if (error.code === "auth/invalid-login-credentials") {
                setError("Incorrect credentials or account does not exist.")
            } else {
                setError(error.message)
            }
            scrollToTop()
        }
    }

    useEffect(() => {
        if (username) {
            const timer = setTimeout(() => {
                checkUsernameAvailability()
            }, 500)
            return () => clearTimeout(timer)
        }
    }, [username])

    useEffect(() => {
        if (aadharDetails) {
            const timer = setTimeout(() => {
                checkAadharAvailability()
            }, 500)
            return () => clearTimeout(timer)
        }
    }, [aadharDetails])

    const payinit = async () => {   
        try {
            const ress = await handlePurchase();
            console.log("ress", ress);
            const response = await get(`${url.PAYMENT_INIT}?amount=${orderDetails.total}&uniquetag=${orderDetails.id}`);
            
            if (response.redirectUrl) {
                console.log('Redirecting to payment gateway:', response.redirectUrl);
                window.location.href = response.redirectUrl;
            } else {
                throw new Error('Redirect URL not received');
            }
        } catch (error) {
            console.error('Payment initiation failed:', error);
            setError('Failed to initiate payment. Please try again.');
            scrollToTop();
        }
    };
    const checkUsernameAvailability = async () => {
        if (!username) {
            return
        }

        try {
            const userCollectionRef = collection(db, "users")
            const q = query(userCollectionRef, where("username", "==", username))
            const querySnapshot = await getDocs(q)
            console.log("Username availability check result:", querySnapshot.empty)

            setUsernameAvailable(querySnapshot.empty)
        } catch (error) {
            console.error("Error checking username availability:", error)
            setError("Failed to check username availability. Please try again.")
            scrollToTop()
        }
    }

    const checkAadharAvailability = async () => {
        if (!aadharDetails) {
            return
        }

        try {
            const userCollectionRef = collection(db, "users")
            const q = query(
                userCollectionRef,
                where("aadhar_number", "==", aadharDetails),
            )
            const querySnapshot = await getDocs(q)
            console.log("Aadhar availability check result:", querySnapshot.empty)

            setAadharAvailable(querySnapshot.empty)
        } catch (error) {
            console.error("Error checking aadhar availability:", error)
            setError("Failed to check aadhar availability. Please try again.")
            scrollToTop()
        }
    }

    const handleRegister = async () => {
        try {
            setError("")
            setSuccess("")

            if (!email || !password || !retypePassword) {
                setError("All fields are required for registration.")
                scrollToTop()
                return
            }

            if (password !== retypePassword) {
                setError("Passwords do not match.")
                scrollToTop()
                return
            }

            console.log("Attempting registration for email:", email)
            const userCredential = await createUserWithEmailAndPassword(
                auth,
                email,
                password,
            )
            const user = userCredential.user
            console.log("Registration successful for user:", user.uid)

            setIsLoggedIn(true)
            setSuccess("Successfully registered!")
            scrollToTop()
        } catch (error) {
            console.error("Error in registration:", error)
            setError(error.message)
            scrollToTop()
        }
    }

    const handleAuth = async () => {
        if (userExists) {
            await handleLogin()
        } else {
            await handleRegister()
        }
    }

    const handlePurchase = async () => {
        try {
            const maxDuration = Math.max(
                ...orderDetails.courses.map(course => course.duration),
            )

            setError("")
            setSuccess("")

            if (!username || !aadharDetails || !paymentMethod) {
                setError("Username, Aadhar Number, Payment Method are required fields.")
                scrollToTop()
                return
            }

            if (paymentMethod === "qr" && !transactionId) {
                setError("Please enter the transaction ID for QR payment.")
                scrollToTop()
                return
            }

            const currentDate = new Date()
            const formattedDate = currentDate
                .toISOString()
                .slice(0, 10)
                .replace(/-/g, "")

            // Find furthest expiry date from courses
            const furthestExpiryDate = orderDetails.courses.reduce(
                (maxDate, course) => {
                    const courseExpiry = new Date(course.expiryDate)
                    return courseExpiry > maxDate ? courseExpiry : maxDate
                },
                new Date(orderDetails.courses[0].expiryDate),
            )

            const purchaseData = {
                expire_at: furthestExpiryDate,
                phonenumber: phoneNumber,
                plan: `${maxDuration}-Months`,
                platform: "Android",
                price: orderDetails.total.toString(),
                purchaseId: "subscription1",
                purchase_at: currentDate,
                user_email: email,
                user_id: auth.currentUser.uid,
                user_name: name,
                transaction_id: transactionId,
                aadhar_number: aadharDetails,
                username: username,
                payment_method: paymentMethod, // 'qr' or 'phonepay'
            }
            console.log("Purchase data prepared:", purchaseData)

            // Create new purchase document
            const purchaseRef = doc(db, "purchases", `${auth.currentUser.uid}`)
            await setDoc(purchaseRef, purchaseData)
            console.log("Purchase document created")

            // Update user stats
            const userStatsRef = doc(db, "user_stats", formattedDate)
            const userStatsDoc = await getDoc(userStatsRef)
            if (userStatsDoc.exists()) {
                await updateDoc(userStatsRef, {
                    count: increment(1),
                    timestamp: currentDate,
                })
                console.log("User stats updated")
            } else {
                await setDoc(userStatsRef, {
                    count: 1,
                    timestamp: currentDate,
                })
                console.log("User stats created")
            }

            // Update purchase stats
            const purchaseStatsRef = doc(db, "purchase_stats", formattedDate)
            const purchaseStatsDoc = await getDoc(purchaseStatsRef)
            if (purchaseStatsDoc.exists()) {
                await updateDoc(purchaseStatsRef, {
                    count: increment(1),
                    timestamp: currentDate,
                })
                console.log("Purchase stats updated")
            } else {
                await setDoc(purchaseStatsRef, {
                    count: 1,
                    timestamp: currentDate,
                })
                console.log("Purchase stats created")
            }

            // Get max course duration

            const userDocRef = doc(db, "users", `${auth.currentUser.uid}`)
            const userData = {
                email,
                created_at: new Date(),
                created_by: orderDetails.creator.username,
                image_url: null,
                isLoggedIn: false,
                name,
                phonenumber: phoneNumber,
                platform: "Android",
                subscription: {
                    end_at: furthestExpiryDate,
                    plan: `${maxDuration}-Months`,
                    product_id: `subscription-${maxDuration}months-₹${orderDetails.total.toString()}`,
                    purchased_at: new Date(),
                },
                transaction_id: transactionId,
                username,
                aadhar_number: aadharDetails,
                disabled: paymentMethod === "qr" ? true : false,
            }
            await setDoc(userDocRef, userData)
            console.log("User document created in Firestore:", userData)

            // API call to update transaction details
            const response = await post(
                url.UPDATE_TRANSACTION_DETAILS + `/${orderDetails.id}`,
                {
                    transaction_id: transactionId,
                    aadhar_number: aadharDetails,
                    username: username,
                },
            )
            console.log("Transaction details update response:", response)

            if (response.message === "success") {
                setSuccess(
                    "Thanks for purchasing with us! Your order has been successfully placed. Your account will be activated within 2-3 working hours.",
                )
                scrollToTop()
                // Reset all states after successful purchase
                setOrderId("")
                setOrderDetails(null)
                setEmail("")
                setPassword("")
                setPaymentMethod("")
                setTransactionId("")
                setUserExists(false)
                setName("")
                setPhoneNumber("")
                setUsername("")
                setAadharDetails("")
                setIsLoggedIn(false)
            } else {
                throw new Error(response.message || "Failed to create order")
            }
        } catch (error) {
            console.error("Error in purchase:", error)
            setError(error.message)
            scrollToTop()
        }
    }

    const handleAuthAndPurchase = async () => {
        try {
            await handleAuth()
            if (isLoggedIn) {
                await handlePurchase()
            }
        } catch (error) {
            console.error("Error in authentication and purchase:", error)
            setError("An error occurred during the process. Please try again.")
            scrollToTop()
        }
    }

    return (
        <div className="container mt-5">
            <div ref={topRef} />
            <Card className="shadow-lg">
                <CardBody>
                    <h2 className="mb-4 text-center text-primary">Order Verification</h2>
                    {error && <Alert color="danger">{error}</Alert>}
                    {success && <Alert color="success">{success}</Alert>}

                    <Form>
                        <FormGroup>
                            <Label for="orderId">Order Unique Verification Code</Label>
                            <Input
                                type="text"
                                id="orderId"
                                value={orderId}
                                onChange={handleOrderIdChange}
                                placeholder="Enter Unique Code"
                                className="mb-2"
                                required
                            />
                            <small className="text-muted">
                                Please enter the verification code sent to you
                            </small>
                        </FormGroup>
                        <Button
                            color="primary"
                            onClick={fetchOrderDetails}
                            className="w-100 mt-3"
                            disabled={!orderId}
                        >
                            {orderId ? "Verify Order" : "Enter Code to Verify"}
                        </Button>
                    </Form>

                    {orderDetails && !isLoggedIn && (
                        <>
                            <div className="alert alert-info mt-4" role="alert">
                                <h5 className="alert-heading mb-2">Course Access:</h5>
                                <p className="mb-0">
                                    Already have course credentials? Login here. New to the
                                    course? Create your course access account. These will be your
                                    login credentials for the course Application.
                                </p>
                            </div>
                            <Nav tabs className="mt-4">
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === "1" })}
                                        onClick={() => {
                                            toggleTab("1")
                                        }}
                                    >
                                        Login
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === "2" })}
                                        onClick={() => {
                                            toggleTab("2")
                                        }}
                                    >
                                        Register
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                <TabPane tabId="1">
                                    <Form className="mt-4">
                                        <FormGroup>
                                            <Label for="email">Email</Label>
                                            <Input
                                                type="email"
                                                id="email"
                                                value={email}
                                                onChange={handleEmailChange}
                                                className="mb-2"
                                                required
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="password">Password</Label>
                                            <Input
                                                type="password"
                                                id="password"
                                                value={password}
                                                onChange={handlePasswordChange}
                                                className="mb-2"
                                                minLength={8}
                                                required
                                            />
                                            <small className="text-muted">
                                                Password must be at least 8 characters long
                                            </small>
                                        </FormGroup>
                                        <Button
                                            color="primary"
                                            onClick={handleLogin}
                                            className="w-100 mt-3"
                                        >
                                            Login
                                        </Button>
                                    </Form>
                                </TabPane>
                                <TabPane tabId="2">
                                    <Form className="mt-4">
                                        <FormGroup>
                                            <Label for="email">Email</Label>
                                            <Input
                                                type="email"
                                                id="email"
                                                value={email}
                                                onChange={handleEmailChange}
                                                className="mb-2"
                                                required
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="password">Password</Label>
                                            <Input
                                                type="password"
                                                id="password"
                                                value={password}
                                                onChange={handlePasswordChange}
                                                className="mb-2"
                                                minLength={8}
                                                required
                                            />
                                            <small className="text-muted">
                                                Password must be at least 8 characters long
                                            </small>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="retypePassword">Retype Password</Label>
                                            <Input
                                                type="password"
                                                id="retypePassword"
                                                value={retypePassword}
                                                onChange={handleRetypePasswordChange}
                                                className="mb-2"
                                                minLength={8}
                                                required
                                            />
                                        </FormGroup>
                                        <Button
                                            color="primary"
                                            onClick={handleRegister}
                                            className="w-100 mt-3"
                                        >
                                            Register
                                        </Button>
                                    </Form>
                                </TabPane>
                            </TabContent>
                        </>
                    )}

                    {orderDetails && isLoggedIn && (
                        <div className="mt-5">
                            <h4 className="mb-3 text-info">Additional Information</h4>
                            <FormGroup>
                                <Label for="username">Username</Label>
                                <Input
                                    type="text"
                                    id="username"
                                    value={username}
                                    onChange={handleUsernameChange}
                                    onBlur={checkUsernameAvailability}
                                    placeholder="Enter username"
                                    className="mb-2"
                                    required
                                    readOnly={isReadOnly}
                                />
                                {!isReadOnly && usernameAvailable && (
                                    <small className="text-success">Username available</small>
                                )}
                                {!isReadOnly && usernameAvailable === false && (
                                    <small className="text-danger">Username already taken</small>
                                )}
                            </FormGroup>
                            <FormGroup>
                                <Label for="aadharDetails">Aadhar Number</Label>
                                <Input
                                    type="number"
                                    id="aadharDetails"
                                    value={aadharDetails}
                                    onChange={e => {
                                        const value = e.target.value
                                        if (value.length <= 12) {
                                            handleAadharDetailsChange(e)
                                        }
                                    }}
                                    placeholder="Enter 12-digit Aadhar number"
                                    className="mb-2"
                                    required
                                    maxLength={12}
                                    min={0}
                                    onKeyPress={e => {
                                        if (!/[0-9]/.test(e.key)) {
                                            e.preventDefault()
                                        }
                                    }}
                                    readOnly={isReadOnly}
                                />
                                {!isReadOnly && aadharAvailable && (
                                    <small className="text-success">
                                        Aadhar number available
                                    </small>
                                )}
                                {!isReadOnly && aadharAvailable === false && (
                                    <small className="text-danger">
                                        Aadhar number already taken
                                    </small>
                                )}
                            </FormGroup>
                            <div className="order-details-section mb-5">
                                <h3 className="mb-4 text-primary fw-bold">Order Details</h3>
                                <div className="table-responsive shadow rounded p-3">
                                    <Table hover responsive className="table-striped mb-0">
                                        <tbody>
                                            <tr className="bg-light">
                                                <th className="py-3 ps-4" width="30%">
                                                    Order ID
                                                </th>
                                                <td className="py-3">{orderDetails.id}</td>
                                            </tr>
                                            <tr>
                                                <th className="py-3 ps-4">Lead Name</th>
                                                <td className="py-3 fw-semibold text-primary">
                                                    {orderDetails.leadName}
                                                </td>
                                            </tr>
                                            <tr className="bg-light">
                                                <th className="py-3 ps-4">Email</th>
                                                <td className="py-3">
                                                    <i className="mdi mdi-email me-2"></i>
                                                    {orderDetails.email}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="py-3 ps-4">Phone Number</th>
                                                <td className="py-3">
                                                    <i className="mdi mdi-phone me-2"></i>
                                                    {orderDetails.phoneNumber}
                                                </td>
                                            </tr>
                                            <tr className="bg-light">
                                                <th className="py-3 ps-4">Order Type</th>
                                                <td className="py-3">
                                                    <span className="badge bg-info text-white px-3 py-2">
                                                        {orderDetails.OrderType}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th className="py-3 ps-4">Status</th>
                                                <td className="py-3">
                                                    <span
                                                        className={`badge bg-${orderDetails.status === "pending" ? "warning" : orderDetails.status === "completed" ? "success" : "danger"} px-3 py-2`}
                                                    >
                                                        <i
                                                            className={`mdi mdi-${orderDetails.status === "pending" ? "clock-outline" : orderDetails.status === "completed" ? "check-circle-outline" : "alert-circle-outline"} me-1`}
                                                        ></i>
                                                        {orderDetails.status
                                                            ? orderDetails.status.toUpperCase()
                                                            : "N/A"}
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>

                            <div className="course-details-section mb-5">
                                <h4 className="mb-4 text-primary fw-bold">Course Details</h4>
                                <div className="table-responsive shadow rounded p-3">
                                    <Table hover responsive className="table-striped mb-0">
                                        <thead className="bg-light">
                                            <tr>
                                                <th className="py-3 px-3">Course Name</th>
                                                <th className="py-3 px-3">Duration</th>
                                                <th className="py-3 px-3">Price</th>
                                                <th className="py-3 px-3">Expiry Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orderDetails.courses.map((course, index) => (
                                                <tr key={index} className="align-middle">
                                                    <td className="py-3 px-3 fw-semibold">
                                                        {course.coursename}
                                                    </td>
                                                    <td className="py-3 px-3">
                                                        <span className="badge bg-info text-white px-3">
                                                            {course.duration} months
                                                        </span>
                                                    </td>
                                                    <td className="py-3 px-3 text-primary fw-bold">
                                                        ₹ {course.price.toFixed(2)}
                                                    </td>
                                                    <td className="py-3 px-3">
                                                        <span className="badge bg-light text-dark px-3">
                                                            {new Date(course.expiryDate).toLocaleDateString()}
                                                        </span>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>

                                <div className="mt-4 p-4 bg-light rounded shadow">
                                    <Row className="align-items-center">
                                        <Col xs={12} className="text-end">
                                            <h4 className="text-success mb-0 d-flex align-items-center justify-content-end">
                                                <span className="me-3">Total Price:</span>
                                                <span className="fs-3">
                                                    ₹ {orderDetails.total.toFixed(2)}
                                                </span>
                                            </h4>
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                            <Form className="mt-4">
                                <h4 className="mb-3 text-info">Payment Options</h4>

                                <FormGroup>
                                    <Label for="paymentMethod">Payment Method</Label>
                                    <Input
                                        type="select"
                                        id="paymentMethod"
                                        value={paymentMethod}
                                        onChange={handlePaymentMethodChange}
                                        className="mb-2"
                                        required
                                    >
                                        <option value="">Select Payment Method</option>
                                        <option value="qr">QR</option>
                                        <option value="phonepay">PhonePe</option>
                                    </Input>
                                </FormGroup>
                                {paymentMethod === "qr" && (
                                    <Row className="justify-content-center align-items-center mt-3">
                                        <Col
                                            md={6}
                                            className="text-center p-3 border rounded shadow"
                                        >
                                            <div className="qr-container p-3 bg-light rounded">
                                                <img
                                                    src={QRCode}
                                                    alt="QR Code"
                                                    style={{
                                                        maxHeight: "300px",
                                                        width: "auto",
                                                        border: "2px solid #ddd",
                                                        borderRadius: "8px",
                                                        padding: "10px",
                                                        backgroundColor: "white",
                                                    }}
                                                    className="mb-3"
                                                />
                                                <p className="mb-3 text-muted font-weight-bold">
                                                    <i className="fas fa-qrcode mr-2"></i>
                                                    Scan QR Code to Pay
                                                </p>
                                                <FormGroup>
                                                    <Label
                                                        for="transactionId"
                                                        className="font-weight-bold"
                                                    >
                                                        Transaction ID
                                                    </Label>
                                                    <Input
                                                        type="text"
                                                        id="transactionId"
                                                        value={transactionId}
                                                        onChange={handleTransactionIdChange}
                                                        placeholder="Enter your transaction ID"
                                                        className="form-control-lg text-center mb-2"
                                                        required
                                                    />
                                                </FormGroup>
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                                {paymentMethod === "phonepay" && (
                                    <Row className="justify-content-center align-items-center mt-3">
                                        <Col
                                            md={6}
                                            className="text-center p-3 border rounded shadow-lg"
                                        >
                                            <div
                                                className="phonepe-container p-4 rounded"
                                                style={{
                                                    background:
                                                        "linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)",
                                                    border: "1px solid #e0e0e0",
                                                }}
                                            >
                                                <div className="amount-display mb-4">
                                                    <h3
                                                        className="text-primary mb-2"
                                                        style={{
                                                            fontSize: "1.5rem",
                                                            fontWeight: "600",
                                                            color: "#333",
                                                        }}
                                                    >
                                                        Amount to Pay
                                                    </h3>
                                                    <h2
                                                        className="font-weight-bold"
                                                        style={{
                                                            fontSize: "2.5rem",
                                                            color: "#5f259f",
                                                        }}
                                                    >
                                                        ₹{orderDetails.total}
                                                    </h2>
                                                </div>
                                                <Button
                                                    color="primary"
                                                    size="lg"
                                                    className="btn-phonepe"
                                                    onClick={payinit}
                                                    style={{
                                                        background:
                                                            "linear-gradient(45deg, #5f259f, #7c3aed)",
                                                        border: "none",
                                                        padding: "15px 40px",
                                                        fontSize: "1.2rem",
                                                        borderRadius: "30px",
                                                        boxShadow: "0 4px 15px rgba(95, 37, 159, 0.3)",
                                                        transition: "all 0.3s ease",
                                                        transform: "translateY(0)",
                                                        cursor: "pointer",
                                                    }}
                                                    onMouseOver={e => {
                                                        e.target.style.transform = "translateY(-2px)"
                                                        e.target.style.boxShadow =
                                                            "0 6px 20px rgba(95, 37, 159, 0.4)"
                                                    }}
                                                    onMouseOut={e => {
                                                        e.target.style.transform = "translateY(0)"
                                                        e.target.style.boxShadow =
                                                            "0 4px 15px rgba(95, 37, 159, 0.3)"
                                                    }}
                                                >
                                                    <i className="fas fa-money-bill-wave mr-2 font-weight-bold"></i>
                                                    Click Here to Pay
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                                <Button
                                    color="primary"
                                    onClick={handlePurchase}
                                    className="w-100 mt-3"
                                >
                                    Purchase
                                </Button>
                            </Form>
                        </div>
                    )}
                </CardBody>
            </Card>
        </div>
    )
}

export default OrderVerification
