import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { get } from 'helpers/api_helper';
import * as url from '../../helpers/url_helper';
import { Card, CardBody, Row, Col, Table, Breadcrumb, BreadcrumbItem, Alert } from 'reactstrap';
import { motion, AnimatePresence } from 'framer-motion';

const TBAProfile = () => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await get(`${url.GET_USER_DETAILS}/${id}`);
                setUser(response);
                setLoading(false);
            } catch (err) {
                setError('Failed to fetch user details');
                setLoading(false);
            }
        };

        fetchUser();
    }, [id]);

    if (loading) return (
        <motion.div 
            className="text-center mt-5"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
            <div className="spinner-border" role="status"></div>
        </motion.div>
    );

    if (error) return (
        <motion.div 
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
        >
            <Alert color="danger" className="mt-3">{error}</Alert>
        </motion.div>
    );

    if (!user) return (
        <motion.div 
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -50 }}
        >
            <Alert color="warning" className="mt-3">No user found</Alert>
        </motion.div>
    );

    const getStatusBadgeColor = (status) => {
        switch(status.toLowerCase()) {
            case 'completed': return 'success';
            case 'pending': return 'warning';
            case 'cancelled': return 'danger';
            default: return 'secondary';
        }
    };

    const placeholderImage = 'https://via.placeholder.com/200x200.png?text=Image+Not+Available';

    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
            >
                <Breadcrumb>
                    <BreadcrumbItem><Link to="/">Home</Link></BreadcrumbItem>
                    <BreadcrumbItem active>TBA Profile</BreadcrumbItem>
                </Breadcrumb>
                <Card className="shadow-sm">
                    <CardBody>
                        <Row className="align-items-center mb-4">
                            <Col md={3}>
                            <img
                                                src={user.aadharCardBack ? `${process.env.REACT_APP_API_URL}/image/files/${user.photo}` : placeholderImage}
                                                alt="Aadhar Card Back"
                                                style={{ width: '100%', height: '200px', objectFit: 'cover' }}
                                                className="img-fluid rounded"
                                            />
                               
                            </Col>
                            <Col md={9}>
                                <h2 className="text-primary">{user.name}</h2>
                                <p className="text-muted mb-0">{user.role}</p>
                                <p className="mb-0"><strong>Email:</strong> {user.email}</p>
                                <p><strong>Contact:</strong> {user.contactNumber}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <motion.div whileHover={{ scale: 1.05 }} transition={{ type: "spring", stiffness: 300 }}>
                                    <Card className="bg-light mb-3">
                                        <CardBody>
                                            <h5 className="card-subtitle mb-3 text-muted">Address Details</h5>
                                            <p><strong>Postal Address:</strong> {user.postalAddress}</p>
                                            <p><strong>Landmark:</strong> {user.landmark}</p>
                                            <p><strong>City:</strong> {user.city}</p>
                                            <p><strong>State:</strong> {user.state}</p>
                                            <p><strong>Pincode:</strong> {user.pincode}</p>
                                        </CardBody>
                                    </Card>
                                </motion.div>
                            </Col>
                            <Col md={6}>
                                <motion.div whileHover={{ scale: 1.05 }} transition={{ type: "spring", stiffness: 300 }}>
                                    <Card className="bg-light mb-3">
                                        <CardBody>
                                            <h5 className="card-subtitle mb-3 text-muted">Bank Details</h5>
                                            <p><strong>Bank Name:</strong> {user.bankName}</p>
                                            <p><strong>Account Number:</strong> {user.accountNumber}</p>
                                            <p><strong>IFSC Code:</strong> {user.ifscCode}</p>
                                            <p><strong>Branch:</strong> {user.branch}</p>
                                        </CardBody>
                                    </Card>
                                </motion.div>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col md={4}>
                                <motion.div whileHover={{ scale: 1.1 }} transition={{ type: "spring", stiffness: 300 }}>
                                    <Card>
                                        <CardBody>
                                            <h5 className="card-subtitle mb-3 text-muted">Aadhar Card Front</h5>
                                            <img
                                                src={user.aadharCardFront ? `${process.env.REACT_APP_API_URL}/image/files/${user.aadharCardFront}` : placeholderImage}
                                                alt="Aadhar Card Front"
                                                style={{ width: '100%', height: '200px', objectFit: 'cover' }}
                                                className="img-fluid rounded"
                                            />
                                        </CardBody>
                                    </Card>
                                </motion.div>
                            </Col>
                            <Col md={4}>
                                <motion.div whileHover={{ scale: 1.1 }} transition={{ type: "spring", stiffness: 300 }}>
                                    <Card>
                                        <CardBody>
                                            <h5 className="card-subtitle mb-3 text-muted">Aadhar Card Back</h5>
                                            <img
                                                src={user.aadharCardBack ? `${process.env.REACT_APP_API_URL}/image/files/${user.aadharCardBack}` : placeholderImage}
                                                alt="Aadhar Card Back"
                                                style={{ width: '100%', height: '200px', objectFit: 'cover' }}
                                                className="img-fluid rounded"
                                            />
                                        </CardBody>
                                    </Card>
                                </motion.div>
                            </Col>
                            <Col md={4}>
                                <motion.div whileHover={{ scale: 1.1 }} transition={{ type: "spring", stiffness: 300 }}>
                                    <Card>
                                        <CardBody>
                                            <h5 className="card-subtitle mb-3 text-muted">PAN Card</h5>
                                            <img
                                                src={user.panCard ? `${process.env.REACT_APP_API_URL}/image/files/${user.panCard}` : placeholderImage}
                                                alt="PAN Card"
                                                style={{ width: '100%', height: '200px', objectFit: 'cover' }}
                                                className="img-fluid rounded"
                                            />
                                        </CardBody>
                                    </Card>
                                </motion.div>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col>
                                <Card>
                                    <CardBody>
                                        <h5 className="card-subtitle mb-3 text-muted">Recent Order Details</h5>
                                        <Table responsive striped hover>
                                            <thead>
                                                <tr>
                                                    <th>Order ID</th>
                                                    <th>Status</th>
                                                    <th>Price</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {user.Orders && user.Orders.length > 0 ? (
                                                    user.Orders.map((order) => (
                                                        <motion.tr key={order.id} whileHover={{ scale: 1.02 }}>
                                                            <td>
                                                                <Link to={`/order/${order.id}`}>
                                                                    {order.id}
                                                                </Link>
                                                            </td>
                                                            <td>
                                                                <span className={`badge bg-${getStatusBadgeColor(order.status)}`}>
                                                                    {order.status}
                                                                </span>
                                                            </td>
                                                            <td>₹{order.total}</td>
                                                        </motion.tr>
                                                    ))
                                                ) : (
                                                    <tr>
                                                        <td colSpan="3" className="text-center">No orders found</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </motion.div>
        </AnimatePresence>
    );
};

export default TBAProfile;
