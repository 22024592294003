import React, { useEffect, useState } from 'react'
import { Row, Col, Card, CardBody, CardTitle, Button, Form, FormGroup, Label, Input, Pagination, PaginationItem, PaginationLink } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import { get } from 'helpers/api_helper'
import * as url from "../../helpers/url_helper"
import { Link } from 'react-router-dom'
import { CSVLink } from "react-csv"
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../store/actions";
import { motion, AnimatePresence } from "framer-motion";
import DatePicker from "react-datepicker";
import { format, parseISO } from 'date-fns';
import "react-datepicker/dist/react-datepicker.css";

const OrderConfirmed = (props) => {
    document.title = "Confirmed Orders";

    const [orders, setOrders] = useState([]);
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [totalItems, setTotalItems] = useState(0);
    const [error, setError] = useState(null);
    const [sortOrder, setSortOrder] = useState('newest');

    // Search states
    const [searchName, setSearchName] = useState('');
    const [searchPhone, setSearchPhone] = useState('');
    const [searchEmail, setSearchEmail] = useState('');

    useEffect(() => {
        const breadcrumbItems = [
            { title: "Orders", link: "#" },
            { title: "Confirmed Orders", link: "#" },
        ];
        props.setBreadcrumbItems('Orders', breadcrumbItems);
    }, []);

    useEffect(() => {
        fetchData();
    }, [currentPage]);

    const fetchData = async () => {
        setLoading(true);
        setError(null);
        try {
            const status = "completed";
            const searchParams = new URLSearchParams();
            searchParams.append('page', currentPage);
            
            if (startDate && endDate) {
                searchParams.append('startDate', format(startDate, 'yyyy-MM-dd'));
                searchParams.append('endDate', format(endDate, 'yyyy-MM-dd'));
            }
            if (searchName) searchParams.append('name', searchName.trim());
            if (searchPhone) searchParams.append('phone', searchPhone.trim());
            if (searchEmail) searchParams.append('email', searchEmail.trim());

            const response = await get(`${url.GET_ORDERS_BY_STATUS}/${status}?${searchParams.toString()}`);
            let sortedOrders = [...response.orders];
            
            // Sort orders based on createdAt date
            sortedOrders.sort((a, b) => {
                const dateA = new Date(a.createdAt);
                const dateB = new Date(b.createdAt);
                return sortOrder === 'newest' ? dateB - dateA : dateA - dateB;
            });
            
            setOrders(sortedOrders);
            setTotalPages(response.totalPages);
            setTotalItems(response.totalItems);
        } catch (error) {
            console.error('Error fetching data:', error);
            setError('Failed to fetch orders. Please try again.');
            setOrders([]);
        } finally {
            setLoading(false);
        }
    }

    const handleSortChange = (e) => {
        const newSortOrder = e.target.value;
        setSortOrder(newSortOrder);
        const sortedOrders = [...orders].sort((a, b) => {
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);
            return newSortOrder === 'newest' ? dateB - dateA : dateA - dateB;
        });
        setOrders(sortedOrders);
    };

    const clearFilters = () => {
        setSearchName('');
        setSearchPhone('');
        setSearchEmail('');
        setStartDate(null);
        setEndDate(null);
        setCurrentPage(1);
        fetchData();
    };

    const handleSearch = () => {
        setCurrentPage(1);
        fetchData();
    };

    const handleDateFilter = (e) => {
        e.preventDefault();
        if (startDate > endDate) {
            alert("Start date must be before end date");
            return;
        }
        setCurrentPage(1);
        fetchData();
    }

    const formatDate = (dateString) => {
        const date = parseISO(dateString);
        return format(date, 'dd-MM-yyyy');
    }

    const csvData = orders.map(item => ({
        TBA: item.Createdby,
        OrderID: item.id,
        LeadName: item.leadName,
        Email: item.email,
        Phone: item.phoneNumber,
        Total: `${item.total} ₹`,
        Status: item.status,
        Commission: `${item.commission} ₹`,
        CreatedDate: formatDate(item.createdAt),
        PaymentDate: item.PaymentDate ? formatDate(item.PaymentDate) : 'N/A'
    }));

    const paginate = pageNumber => setCurrentPage(pageNumber);

    return (
        <React.Fragment>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
            >
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <CardTitle className="h4">Confirmed Orders</CardTitle>
                                
                                <div className="mb-4 p-3 bg-light rounded">
                                    <Row>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label>Name</Label>
                                                <Input 
                                                    type="text" 
                                                    value={searchName}
                                                    onChange={(e) => setSearchName(e.target.value)}
                                                    placeholder="Search by name"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label>Phone Number</Label>
                                                <Input 
                                                    type="text"
                                                    value={searchPhone}
                                                    onChange={(e) => setSearchPhone(e.target.value)}
                                                    placeholder="Search by phone"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label>Email</Label>
                                                <Input 
                                                    type="email"
                                                    value={searchEmail}
                                                    onChange={(e) => setSearchEmail(e.target.value)}
                                                    placeholder="Search by email"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3} className="d-flex align-items-end mb-3">
                                            <Button 
                                                color="primary" 
                                                onClick={handleSearch}
                                                className="me-2"
                                            >
                                                Search
                                            </Button>
                                            <Button 
                                                color="secondary" 
                                                onClick={clearFilters}
                                            >
                                                Clear
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>

                                <Form onSubmit={handleDateFilter} className="mb-3">
                                    <Row className="align-items-center">
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label for="startDate">Start Date</Label>
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={date => setStartDate(date)}
                                                    dateFormat="dd/MM/yyyy"
                                                    className="form-control form-control-lg"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label for="endDate">End Date</Label>
                                                <DatePicker
                                                    selected={endDate}
                                                    onChange={date => setEndDate(date)}
                                                    dateFormat="dd/MM/yyyy"
                                                    className="form-control form-control-lg"
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={2} className="d-flex align-items-end">
                                            <Button color="primary" type="submit" size="lg" className="w-100">Filter</Button>
                                        </Col>
                                        <Col md={2} className="d-flex align-items-end">
                                            <CSVLink data={csvData} filename={"confirmed_orders.csv"} className="btn btn-primary btn-lg w-100">
                                                Download CSV
                                            </CSVLink>
                                        </Col>
                                        <Col md={2} className="d-flex align-items-end mt-3">
                                            <FormGroup size="lg" className="w-100">
                                                <Input
                                                    type="select"
                                                    value={sortOrder}
                                                    onChange={handleSortChange}
                                                    className="form-control-lg btn btn-primary"
                                                >
                                                    <option value="newest">Newest First</option>
                                                    <option value="oldest">Oldest First</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>

                                <AnimatePresence>
                                    {loading ? (
                                        <motion.div
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                            className="text-center"
                                        >
                                            <div className="spinner-border text-primary" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </motion.div>
                                    ) : (
                                        <motion.div
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            exit={{ opacity: 0 }}
                                            className="table-rep-plugin"
                                        >
                                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                <Table id="tech-companies-1" className="table table-striped table-bordered">
                                                    <Thead>
                                                        <Tr>
                                                            <Th>TBA</Th>
                                                            <Th>Order ID</Th>
                                                            <Th>Lead Name</Th>
                                                            <Th>Email</Th>
                                                            <Th>Phone</Th>
                                                            <Th>Total</Th>
                                                            <Th>Status</Th>
                                                            <Th className="text-center">Commission</Th>
                                                            <Th>Created Date</Th>
                                                            <Th>Payment Date</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {orders.length > 0 ? (
                                                            orders.map((item, index) => (
                                                                <Tr key={index}>
                                                                    <Td>{item.createdByUsername}</Td>
                                                                    <Td>
                                                                        <Link to={`/order/${item.id}`}>
                                                                            {item.id}
                                                                            {" "}
                                                                            <i className="fas fa-link mr-1"></i>
                                                                        </Link>
                                                                    </Td>
                                                                    <Td>{item.leadName}</Td>
                                                                    <Td>{item.email}</Td>
                                                                    <Td>{item.phoneNumber}</Td>
                                                                    <Td>{item.total} ₹</Td>
                                                                    <Td>{item.status}</Td>
                                                                    <Td className="text-center">{item.commission} ₹</Td>
                                                                    <Td>{formatDate(item.createdAt)}</Td>
                                                                    <Td>{item.PaymentDate ? formatDate(item.PaymentDate) : 'N/A'}</Td>
                                                                </Tr>
                                                            ))
                                                        ) : (
                                                            <Tr>
                                                                <Td colSpan="10">No confirmed orders available</Td>
                                                            </Tr>   
                                                        )}
                                                    </Tbody>
                                                </Table>
                                            </div>
                                        </motion.div>
                                    )}
                                </AnimatePresence>
                                <div className="d-flex justify-content-between align-items-center mt-3">
                                    <Pagination className="pagination justify-content-center">
                                        <PaginationItem disabled={currentPage === 1}>
                                            <PaginationLink previous onClick={() => paginate(currentPage - 1)} />
                                        </PaginationItem>
                                        {[...Array(totalPages)].map((_, i) => (
                                            <PaginationItem active={i + 1 === currentPage} key={i}>
                                                <PaginationLink onClick={() => paginate(i + 1)}>
                                                    {i + 1}
                                                </PaginationLink>
                                            </PaginationItem>
                                        ))}
                                        <PaginationItem disabled={currentPage === totalPages}>
                                            <PaginationLink next onClick={() => paginate(currentPage + 1)} />
                                        </PaginationItem>
                                    </Pagination>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </motion.div>
        </React.Fragment>
    );
}

export default connect(null, { setBreadcrumbItems })(OrderConfirmed);
