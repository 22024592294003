import React from 'react';
import { Row, Card, CardBody, CardTitle } from "reactstrap";
import { getCurrentUser } from "../../helpers/url_helper";

const YearlySales = ({ yearlyRevenue }) => {
    const currentUser = getCurrentUser();
    const isAdmin = currentUser.role === 'admin';

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <CardTitle className="h4 mb-4">Yearly {isAdmin ? 'Sales' : 'Commission'}</CardTitle>

                    <Row className="text-center mt-4">
                        <div className="col-12">
                            <h5 className="font-size-20">{yearlyRevenue.toFixed(2)} ₹</h5>
                            <p className="text-muted">Total Yearly {isAdmin ? 'Sales' : 'Commission'}</p>
                        </div>
                    </Row>
                    <div dir="ltr">
                        {/* You can add a chart here if needed */}
                    </div>

                </CardBody>
            </Card>
        </React.Fragment>
    );
}

export default YearlySales;