import React, { useState, useEffect } from 'react';
import { Row, Col, Card, CardBody, CardTitle, Table, Spinner, Alert, Pagination, PaginationItem, PaginationLink, Button, Modal, ModalBody, ModalFooter, Input, FormGroup, Label } from "reactstrap";
import { get, put, post } from 'helpers/api_helper';
import * as url from "../../helpers/url_helper";
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../store/actions";
import { CSVLink } from "react-csv";
import { isAdmin } from "../../helpers/url_helper";

const StyledTable = styled(Table)`
  th, td {
    vertical-align: middle;
  }
`;

const SearchContainer = styled.div`
  margin-bottom: 20px;
  padding: 15px;
  background: #f8f9fa;
  border-radius: 5px;
`;

const InterestSelect = styled.select`
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ced4da;
  background-color: ${props => props.color};
  color: ${props => ['#FFA500', '#FFFFE0'].includes(props.color) ? 'black' : 'white'};
  font-weight: bold;
  transition: all 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
`;

const Listlead = (props) => {
    document.title = "List Leads";
    const [leads, setLeads] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const pageSize = 50;
    const [showModal, setShowModal] = useState(false);
    const [isLeadRequested, setIsLeadRequested] = useState(false);
    const [notification, setNotification] = useState('');
    const [sortOrder, setSortOrder] = useState('newest'); // newest or oldest
    
    // Search states
    const [searchName, setSearchName] = useState('');
    const [searchPhone, setSearchPhone] = useState('');
    const [searchEmail, setSearchEmail] = useState('');

    const breadcrumbItems = [
        { title: "Leads", link: "#" }
    ];

    useEffect(() => {
        props.setBreadcrumbItems('Leads', breadcrumbItems);
        fetchLeads();
    }, [currentPage]); // Only fetch on page change, not on search params change

    const checkLeadRequestStatus = async () => {
        try {
            const response = await get(url.CHECK_LEAD_REQUEST);
            setIsLeadRequested(response.isRequested);
            setShowModal(true);
        } catch (error) {
            console.error('Error checking lead request status:', error);
        }
    };

    const requestLeads = async () => {
        try {
            await post(url.REQUEST_LEADS);
            setShowModal(false);
            setIsLeadRequested(true);
            setNotification('Lead request sent successfully');
        } catch (error) {
            console.error('Error requesting leads:', error);
        }
    };

    const clearFilters = () => {
        setSearchName('');
        setSearchPhone('');
        setSearchEmail('');
        fetchLeads(); // Fetch leads without search params
    };

    const handleSearch = () => {
        fetchLeads(); // This will use current search params
    };

    const handleSort = (order) => {
        setSortOrder(order);
        const sortedLeads = [...leads].sort((a, b) => {
            if (order === 'newest') {
                return new Date(b.createdAt) - new Date(a.createdAt);
            } else {
                return new Date(a.createdAt) - new Date(b.createdAt);
            }
        });
        setLeads(sortedLeads);
    };

    const fetchLeads = async () => {
        setError(null);
        try {
            setLoading(true);
            const searchParams = new URLSearchParams();
            searchParams.append('page', currentPage);
            searchParams.append('pageSize', pageSize);
            if (searchName) searchParams.append('name', searchName);
            if (searchPhone) searchParams.append('phone', searchPhone);
            if (searchEmail) searchParams.append('email', searchEmail);
            
            const response = await get(`${url.GET_LEAD}?${searchParams.toString()}`);
            if (response.status === 0) {
                setError(response.message || 'Error fetching leads');
                setLeads([]);
                setTotalPages(0);
                return;
            }
            const fetchedLeads = Array.isArray(response.data) ? response.data : [];
            // Sort leads based on current sortOrder
            const sortedLeads = [...fetchedLeads].sort((a, b) => {
                if (sortOrder === 'newest') {
                    return new Date(b.createdAt) - new Date(a.createdAt);
                } else {
                    return new Date(a.createdAt) - new Date(b.createdAt);
                }
            });
            setLeads(sortedLeads);
            setTotalPages(Math.ceil(response.total / pageSize));
        } catch (err) {
            console.error('Error fetching leads:', err);
            setError(err?.response?.data?.message || err?.message || 'An error occurred while fetching leads');
            setLeads([]);
            setTotalPages(0);
        } finally {
            setLoading(false);
        }
    };

    const updateInterest = async (id, newInterest) => {
        try {
            await put(`${url.UPDATE_LEAD_INTEREST}/${id}`, { interest: newInterest });
            setLeads(leads.map(lead =>
                lead.id === id ? { ...lead, interest: newInterest } : lead
            ));
        } catch (err) {
            console.error('Failed to update interest:', err);
        }
    };

    const getInterestColor = (interest) => {
        const colors = {
            notcalled: '#FFFFE0',
            interested: '#90EE90',
            notinterested: '#FFA07A',
            completed: '#FFA500'
        };
        return colors[interest] || 'inherit';
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const csvData = leads.map(lead => ({
        ID: lead.id,
        Name: lead.name,
        'Phone Number': lead.phoneNumber,
        Email: lead.email,
        State: lead.state,
        City: lead.city,
        Interest: lead.interest
    }));

    if (loading) return <Spinner color="primary" />;
    if (error) return <Alert color="danger">{error}</Alert>;

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle tag="h4">List of Leads</CardTitle>
                            
                            <SearchContainer>
                                <Row className="align-items-end">
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label>Name</Label>
                                            <Input 
                                                type="text" 
                                                value={searchName}
                                                onChange={(e) => setSearchName(e.target.value)}
                                                placeholder="Search by name"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label>Phone Number</Label>
                                            <Input 
                                                type="text"
                                                value={searchPhone}
                                                onChange={(e) => setSearchPhone(e.target.value)}
                                                placeholder="Search by phone"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label>Email</Label>
                                            <Input 
                                                type="email"
                                                value={searchEmail}
                                                onChange={(e) => setSearchEmail(e.target.value)}
                                                placeholder="Search by email"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={3} className="d-flex align-items-center mb-3">
                                        <Button 
                                            color="primary" 
                                            onClick={handleSearch}
                                            className="me-2"
                                        >
                                            Search
                                        </Button>
                                        <Button 
                                            color="secondary" 
                                            onClick={clearFilters}
                                        >
                                            Clear
                                        </Button>
                                    </Col>
                                </Row>
                            </SearchContainer>

                            <div className="d-flex justify-content-between mb-3">
                                {isAdmin() && (
                                    <CSVLink data={csvData} filename={"leads.csv"}>
                                        <Button color="primary">Download CSV</Button>
                                    </CSVLink>
                                )}
                                {!isAdmin() && (
                                    <Button color="primary" onClick={checkLeadRequestStatus}>
                                        Request Leads
                                    </Button>
                                )}
                                <FormGroup className="mb-0 d-flex align-items-center">
                                    <Label className="me-2 mb-0" style={{whiteSpace: 'nowrap'}}>Sort by:</Label>
                                    <Input type="select" value={sortOrder} onChange={(e) => handleSort(e.target.value)}>
                                        <option value="newest">Newest First</option>
                                        <option value="oldest">Oldest First</option>
                                    </Input>
                                </FormGroup>
                            </div>

                            <StyledTable responsive striped bordered>
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Name</th>
                                        <th>Phone Number</th>
                                        <th>Email</th>
                                        <th>State</th>
                                        <th>City</th>
                                        <th>Interest</th>
                                        {isAdmin() && <th>TBA ID</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    {leads.length > 0 ? (
                                        leads.map((item) => (
                                            <tr key={item.id}>
                                                <td>{item.id}</td>
                                                <td>
                                                    {isAdmin() ? (
                                                        <Link to={`/lead/${item.id}`}>
                                                            {item.name}{"  "}
                                                            <i className="fas fa-link mr-1"></i>
                                                        </Link>
                                                    ) : (
                                                        item.name
                                                    )}
                                                </td>
                                                <td>{item.phoneNumber}</td>
                                                <td>{item.email}</td>
                                                <td>{item.state}</td>
                                                <td>{item.city}</td>
                                                <td>
                                                    <InterestSelect
                                                        value={item.interest}
                                                        onChange={(e) => updateInterest(item.id, e.target.value)}
                                                        color={getInterestColor(item.interest)}
                                                    >
                                                        <option value="notcalled">Not Called</option>
                                                        <option value="interested">Interested</option>
                                                        <option value="notinterested">Not Interested</option>
                                                        <option value="completed">Completed</option>
                                                    </InterestSelect>
                                                </td>
                                                {isAdmin() && (
                                                    <td>
                                                        {item.assignedTBAName}
                                                    </td>
                                                )}
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={isAdmin() ? "8" : "7"}>No leads available</td>
                                        </tr>
                                    )}
                                </tbody>
                            </StyledTable>
                            <div className="d-flex justify-content-center">
                                <Pagination>
                                    <PaginationItem disabled={currentPage === 1}>
                                        <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)} />
                                    </PaginationItem>
                                    {[...Array(totalPages)].map((_, i) => (
                                        <PaginationItem key={i} active={i + 1 === currentPage}>
                                            <PaginationLink onClick={() => handlePageChange(i + 1)}>
                                                {i + 1}
                                            </PaginationLink>
                                        </PaginationItem>
                                    ))}
                                    <PaginationItem disabled={currentPage === totalPages}>
                                        <PaginationLink next onClick={() => handlePageChange(currentPage + 1)} />
                                    </PaginationItem>
                                </Pagination>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Modal isOpen={showModal} toggle={() => setShowModal(false)}>
                <ModalBody>
                    {isLeadRequested ? 'Leads are already requested' : 'Do you want to request leads from admins?'}
                </ModalBody>
                <ModalFooter>
                    {!isLeadRequested && (
                        <Button color="primary" onClick={requestLeads}>Send Request</Button>
                    )}
                    <Button color="secondary" onClick={() => setShowModal(false)}>Close</Button>
                </ModalFooter>
            </Modal>

            {notification && (
                <div className="alert alert-info" role="alert">
                    {notification}
                </div>
            )}
        </React.Fragment>
    );
};

export default connect(null, { setBreadcrumbItems })(Listlead);