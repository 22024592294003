import React, { useState, useRef, useEffect } from "react"
import { post } from "helpers/api_helper"
import * as url from "../../helpers/url_helper"
import {
    Alert,
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Spinner,
} from "reactstrap"
import Papa from "papaparse"
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"
import { motion, AnimatePresence } from "framer-motion"
import { isAdmin } from "../../helpers/url_helper"

const AddLead = props => {
    document.title = "Add Leads"

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phoneNumber: "",
        city: "",
        state: "",
    })
    const [error, setError] = useState("")
    const [success, setSuccess] = useState("")
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [csvFile, setCsvFile] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const fileInputRef = useRef(null)

    const breadcrumbItems = [
        { title: "Leads", link: "#" },
        { title: "Add Lead", link: "#" },
    ]

    useEffect(() => {
        if (!isAdmin()) {
            setError("Access denied. Only admins can add leads.")
            return
        }
        props.setBreadcrumbItems("Add Lead", breadcrumbItems)
    }, [])

    const handleChange = e => {
        const { id, value } = e.target
        setFormData(prevState => ({
            ...prevState,
            [id]: value,
        }))
    }

    const handleSubmit = async e => {
        e.preventDefault()
        if (!isAdmin()) {
            setError("Access denied. Only admins can add leads.")
            return
        }
        setError("")
        setSuccess("")
        setIsLoading(true)
        try {
            const response = await post(url.ADD_LEAD, formData)

            if (response && response.status === 200) {
                setSuccess(response.message)
                setFormData({
                    name: "",
                    email: "",
                    phoneNumber: "", 
                    city: "",
                    state: "",
                })
            } else {
                setError("Failed to add lead")
            }
        } catch (error) {
            if (error.response?.data?.message) {
                setError(error.response.data.message)
            } else {
                setError(error?.message || 'An error occurred')
            }
        } finally {
            setIsLoading(false)
        }
    }

    const toggleModal = () => {
        if (!isAdmin()) {
            setError("Access denied. Only admins can upload CSV.")
            return
        }
        setIsModalOpen(!isModalOpen)
    }

    const handleFileChange = e => {
        setCsvFile(e.target.files[0])
    }

    const handleUpload = () => {
        if (!isAdmin()) {
            setError("Access denied. Only admins can upload CSV.")
            return
        }

        if (csvFile) {
            setIsLoading(true)
            Papa.parse(csvFile, {
                complete: async results => {
                    const leads = results.data
                        .map(row => ({
                            name: (row.Name || "").trim(),
                            phoneNumber: (row["Mobile No"] || "").trim(),
                            email: (row.Email || "").toLowerCase().trim(),
                            city: (row.City || "").trim(),
                            state: (row.State || "").trim(),
                        }))
                        .filter(lead => lead.name || lead.phoneNumber || lead.email)

                    if (leads.length === 0) {
                        setError("No valid leads found in CSV")
                        setIsLoading(false)
                        return
                    }

                    try {
                        setSuccess("")
                        setError("")
                        const response = await post(url.ADD_LEAD_CSV, { leads })
                        if (response.summary) {
                            const successMessage = `${response.message}\n\nSummary:\nTotal Processed: ${response.summary.totalProcessed}\nSuccessful: ${response.summary.successful}\nDuplicates: ${response.summary.duplicates}\nInvalid: ${response.summary.invalid}`
                            setSuccess(successMessage)
                        } else {
                            setError("Invalid response format")
                        }
                    } catch (error) {
                        if (error.response?.data?.message) {
                            setError(error.response.data.message) 
                        } else {
                            setError("An error occurred while uploading CSV")
                        }
                    } finally {
                        setIsLoading(false)
                        toggleModal()
                        setCsvFile(null)
                        if (fileInputRef.current) {
                            fileInputRef.current.value = ""
                        }
                    }
                },
                header: true,
                skipEmptyLines: true,
                error: error => {
                    setError("Error parsing CSV file")
                    setIsLoading(false)
                },
            })
        } else {
            setError("Please select a CSV file to upload")
        }
    }

    if (!isAdmin()) {
        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
            >
                <Alert color="danger">Access denied. Only admins can add leads.</Alert>
            </motion.div>
        )
    }

    return (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
        >
            <div className="mt-5 mt-lg-4">
                <Row className="mb-3">
                    <h5 className="font-size-14 mb-4">
                        <i className="mdi mdi-arrow-right text-primary me-1"></i>
                        Add Leads info
                    </h5>
                    <div>
                        <motion.button
                            className="btn btn-primary w-lg"
                            onClick={toggleModal}
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            Upload CSV
                        </motion.button>
                    </div>
                </Row>

                <AnimatePresence>
                    {success && (
                        <motion.div
                            initial={{ opacity: 0, y: -50 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -50 }}
                        >
                            <Alert color="success" style={{whiteSpace: 'pre-line'}}>{success}</Alert>
                        </motion.div>
                    )}
                    {error && (
                        <motion.div
                            initial={{ opacity: 0, y: -50 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -50 }}
                        >
                            <Alert color="danger">{error}</Alert>
                        </motion.div>
                    )}
                </AnimatePresence>

                <form onSubmit={handleSubmit}>
                    <div className="row mb-4">
                        <label htmlFor="name" className="col-sm-3 col-form-label">
                            Name
                        </label>
                        <div className="col-sm-9">
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                placeholder="Enter name"
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <label htmlFor="email" className="col-sm-3 col-form-label">
                            Email
                        </label>
                        <div className="col-sm-9">
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                placeholder="Enter email"
                                value={formData.email}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <label htmlFor="phoneNumber" className="col-sm-3 col-form-label">
                            Phone Number
                        </label>
                        <div className="col-sm-9">
                            <input
                                type="tel"
                                className="form-control"
                                id="phoneNumber"
                                placeholder="Enter phone number (numbers only, 10 digits)"
                                value={formData.phoneNumber}
                                onChange={e => {
                                    const phoneValue = e.target.value
                                    if (/^\d{0,10}$/.test(phoneValue)) {
                                        handleChange(e)
                                    }
                                }}
                                pattern="[0-9]{10}"
                                maxLength={10}
                                required
                            />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <label htmlFor="city" className="col-sm-3 col-form-label">
                            City
                        </label>
                        <div className="col-sm-9">
                            <input
                                type="text"
                                className="form-control"
                                id="city"
                                placeholder="Enter city"
                                value={formData.city}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className="row mb-4">
                        <label htmlFor="state" className="col-sm-3 col-form-label">
                            State
                        </label>
                        <div className="col-sm-9">
                            <input
                                type="text"
                                className="form-control"
                                id="state"
                                placeholder="Enter state"
                                value={formData.state}
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div className="row justify-content-end">
                        <div className="col-sm-9">
                            <div>
                                <motion.button
                                    type="submit"
                                    className="btn btn-primary w-lg"
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    disabled={isLoading}
                                >
                                    {isLoading ? (
                                        <Spinner size="sm" color="light" />
                                    ) : (
                                        "Submit"
                                    )}
                                </motion.button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>

            <Modal isOpen={isModalOpen} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Upload CSV</ModalHeader>
                <ModalBody>
                    <input
                        type="file"
                        accept=".csv"
                        onChange={handleFileChange}
                        ref={fileInputRef}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleUpload} disabled={isLoading}>
                        {isLoading ? <Spinner size="sm" color="light" /> : "Upload"}
                    </Button>
                    <Button color="secondary" onClick={toggleModal}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </motion.div>
    )
}

export default connect(null, { setBreadcrumbItems })(AddLead)
