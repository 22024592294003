import axios from "axios";

// Apply base URL for axios
const API_URL = process.env.REACT_APP_API_URL || "http://localhost:5555/api";

const axiosApi = axios.create({
  baseURL: API_URL,
});

// Request interceptor for API calls
axiosApi.interceptors.request.use(
  (config) => {
    const authUser = JSON.parse(localStorage.getItem('authUser'));
    if (authUser && authUser.token) {
      config.headers.Authorization = `Bearer ${authUser.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 403 && !originalRequest._retry) {
      // originalRequest._retry = true;
      try {
        const newToken = await refreshAccessToken();
        axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
        return axiosApi(originalRequest);
      } catch (refreshError) {
        // Handle refresh token error (e.g., redirect to login)
        localStorage.removeItem('authUser');
        window.location.href = '/login';
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export async function get(url, config = {}) {
  try {
    const response = await axiosApi.get(url, config);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
}

export async function post(url, data, config = {}) {
  try {
    const response = await axiosApi.post(url, data, config);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
}

export async function put(url, data, config = {}) {
  try {
    const response = await axiosApi.put(url, data, config);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
}

export async function del(url, config = {}) {
  try {
    const response = await axiosApi.delete(url, config);
    return response.data;
  } catch (error) {
    handleApiError(error);
  }
}

function handleApiError(error) {
  if (error.response) {
    console.error("Data:", error.response.data);
    console.error("Status:", error.response.status);
    console.error("Headers:", error.response.headers);
  } else if (error.request) {
    console.error("Request:", error.request);
  } else {
    console.error("Error:", error.message);
  }
  console.error("Config:", error.config);
  
  throw error;
}

async function refreshAccessToken() {
  try {
    const response = await axios.post(`${API_URL}/auth/refresh-token`, {}, {
      withCredentials: true
    });
    const { token } = response.data;
    const authUser = JSON.parse(localStorage.getItem('authUser'));
    authUser.token = token;
    localStorage.setItem('authUser', JSON.stringify(authUser));
    return token;
  } catch (error) {
    throw error;
  }
}