import React, { useEffect, useState } from 'react'
import { Row, Col, Card, CardBody, CardTitle, Button, Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Alert } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import { get, del, post, put } from 'helpers/api_helper'
import * as url from "../../helpers/url_helper"
import { getCurrentUser } from "../../helpers/url_helper"
import { CSVLink } from "react-csv"
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../store/actions";
import { isAdmin } from '../../helpers/url_helper';

const ListCourse = (props) => {
    document.title = "Courses";
    
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const currentUser = getCurrentUser();

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(50);
    const [modal, setModal] = useState(false);
    const [editCourse, setEditCourse] = useState(null);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);

    useEffect(() => {
        const breadcrumbItems = [
            { title: "Courses", link: "#" },
            { title: "List Courses", link: "#" },
        ];
        props.setBreadcrumbItems('Courses', breadcrumbItems);
    }, []);

    const fetchData = async () => {
        try {
            setLoading(true);
            const response = await get(url.GET_COURSES);
            console.log("API Response:", response);
            setData(response.data || response);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            setError('Failed to fetch courses. Please try again.');
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);
   
    if (loading) {
        return <div>Loading...</div>;
    }

    const handleDelete = async (id) => {
        if (window.confirm('Are you sure you want to delete this course?')) {
            try {
                await put(`${url.DELETE_COURSE}/${id}`);
                setData(data.filter(item => item.id !== id));
                setSuccess('Course deleted successfully');
            } catch (error) {
                console.error('Error deleting course:', error);
                setError('Failed to delete course. Please try again.');
            }
        }
    };

    const handleEdit = (course) => {
        setEditCourse(course);
        setModal(true);
    };

    const handleEditSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setSuccess(null);
        try {
            const response = await put(`${url.EDIT_COURSE}/${editCourse.id}`, editCourse);
            if (response.message === "Course updated successfully") {
                setData(data.map(item => item.id === editCourse.id ? editCourse : item));
                setModal(false);
                setSuccess('Course updated successfully');
                fetchData(); // Re-fetch data to ensure we have the latest
            } else {
                setError('Failed to update course. Please try again.');
            }
        } catch (error) {
            console.error('Error updating course:', error);
            setError('Failed to update course. Please try again.');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditCourse({ ...editCourse, [name]: value });
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = pageNumber => setCurrentPage(pageNumber);

    const csvData = data.map((item, index) => ({
        serialNumber: index + 1,
        coursename: item.coursename,
        duration: item.duration,
        price: item.price,
        commission: item.commissionValue,
        renewalCommission: item.renewalCommissionValue,
        createdAt: item.createdAt ? new Date(item.createdAt).toLocaleDateString('en-GB') : "N/A"
    }));

    return (
        <React.Fragment>
            {error && <Alert color="danger">{error}</Alert>}
            {success && <Alert color="success">{success}</Alert>}
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle className="h4 d-flex justify-content-between align-items-center">
                                <span>Courses</span>
                                {isAdmin() && (
                                    <CSVLink data={csvData} filename={"courses.csv"}>
                                        <Button color="primary" size="sm">
                                            Download CSV
                                        </Button>
                                    </CSVLink>
                                )}
                            </CardTitle>
                            <div className="table-rep-plugin">
                                <div
                                    className="table-responsive mb-0"
                                    data-pattern="priority-columns"
                                >
                                    <Table
                                        id="tech-companies-1"
                                        className="table table-striped table-bordered"
                                    >
                                        <Thead>
                                            <Tr>
                                                <Th>S.No</Th>
                                                <Th>Course Name</Th>
                                                <Th data-priority="1" style={{ textAlign: 'center' }}>Duration</Th>
                                                <Th data-priority="3" style={{ textAlign: 'center' }}>Price</Th>
                                                <Th data-priority="3" style={{ textAlign: 'center' }}>Commission</Th>
                                                <Th data-priority="3" style={{ textAlign: 'center' }}>Renewal Commission</Th>
                                                <Th data-priority="1" style={{ textAlign: 'center' }}>Created At</Th>
                                                {isAdmin() && <Th data-priority="1" style={{ textAlign: 'center' }}>Actions</Th>}
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {Array.isArray(currentItems) && currentItems.length > 0 ? (
                                                currentItems.map((item, index) => (
                                                    <Tr key={index}>
                                                        <Td>{indexOfFirstItem + index + 1}</Td>
                                                        <Th>
                                                            <span className="co-name">{item.coursename}</span>
                                                        </Th>
                                                        <Td style={{ textAlign: 'center' }}>{item.duration}</Td>
                                                        <Td style={{ textAlign: 'center' }}>{item.price} ₹</Td>
                                                        <Td style={{ textAlign: 'center' }}>{item.commissionValue || 'N/A'} ₹</Td>
                                                        <Td style={{ textAlign: 'center' }}>{item.renewalCommissionValue || 'N/A'} ₹</Td>
                                                        <Td style={{ textAlign: 'center' }}>{item.createdAt ? new Date(item.createdAt).toLocaleDateString('en-GB') : "N/A"}</Td>
                                                        {isAdmin() && (
                                                            <Td style={{ textAlign: 'center' }}>
                                                                <Button color="primary" size="sm" className="me-2" onClick={() => handleEdit(item)}>
                                                                    Edit
                                                                </Button>
                                                                <Button color="danger" size="sm" onClick={() => handleDelete(item.id)}>
                                                                    Delete
                                                                </Button>
                                                            </Td>
                                                        )}
                                                    </Tr>
                                                ))
                                            ) : (
                                                <Tr>
                                                    <Td colSpan={isAdmin() ? "8" : "7"}>No data available</Td>
                                                </Tr>   
                                            )}
                                        </Tbody>
                                    </Table>
                                </div>
                            </div>
                            <Pagination className="mt-3 justify-content-center">
                                {[...Array(Math.ceil(data.length / itemsPerPage)).keys()].map(number => (
                                    <PaginationItem key={number + 1} active={number + 1 === currentPage}>
                                        <PaginationLink onClick={() => paginate(number + 1)}>
                                            {number + 1}
                                        </PaginationLink>
                                    </PaginationItem>
                                ))}
                            </Pagination>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

            <Modal isOpen={modal} toggle={() => setModal(!modal)}>
                <ModalHeader toggle={() => setModal(!modal)}>Edit Course</ModalHeader>
                <ModalBody>
                    <Form onSubmit={handleEditSubmit}>
                        <FormGroup>
                            <Label for="coursename">Course Name</Label>
                            <Input
                                type="text"
                                name="coursename"
                                id="coursename"
                                value={editCourse?.coursename || ''}
                                onChange={handleInputChange}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="duration">Duration (in months)</Label>
                            <Input
                                type="number"
                                name="duration"
                                id="duration"
                                value={editCourse?.duration || ''}
                                onChange={handleInputChange}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="price">Price (in Rs)</Label>
                            <Input
                                type="number"
                                name="price"
                                id="price"
                                value={editCourse?.price || ''}
                                onChange={handleInputChange}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="commissionValue">Commission Value</Label>
                            <Input
                                type="number"
                                name="commissionValue"
                                id="commissionValue"
                                value={editCourse?.commissionValue || ''}
                                onChange={handleInputChange}
                                required
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="renewalCommissionValue">Renewal Commission Value</Label>
                            <Input
                                type="number"
                                name="renewalCommissionValue"
                                id="renewalCommissionValue"
                                value={editCourse?.renewalCommissionValue || ''}
                                onChange={handleInputChange}
                                required
                            />
                        </FormGroup>
                        <Button color="primary" type="submit">Update Course</Button>
                    </Form>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}

export default connect(null, { setBreadcrumbItems })(ListCourse)
