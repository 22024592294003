import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Button, Alert } from "reactstrap";
import { GET_PROFILE, UPLOAD_IMAGE ,UPDATE_PROFILE} from "../../helpers/url_helper";
import axios from "axios";
import { get, post } from "helpers/api_helper";

const UserProfile = () => {
  const [user, setUser] = useState({
    username: '',
    email: '',
    name: '',
    contactNumber: '',
    postalAddress: '',
    landmark: '',
    city: '',
    state: '',
    pincode: '',
    UPI: '',
    bankName: '',
    accountNumber: '',
    ifscCode: '',
    branch: '',
    photo: '',
    aadharCardFront: '',
    aadharCardBack: '',
    panCard: '',
    role: ''
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [previews, setPreviews] = useState({
    photo: '',
    aadharCardFront: '',
    aadharCardBack: '',
    panCard: ''
  });

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const fetchUserProfile = async () => {
    try {
      const response = await get(GET_PROFILE);
      if (response) {
        setUser(response);
        setPreviews({
          photo: response.photo || '',
          aadharCardFront: response.aadharCardFront || '',
          aadharCardBack: response.aadharCardBack || '',
          panCard: response.panCard || ''
        });
      } else {
        throw new Error('Invalid response data');
      }
      setLoading(false);
    } catch (err) {
      console.error("Error fetching user profile:", err);
      setError("Error fetching user profile");
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handleFileChange = async (e, type) => {
    const file = e.target.files[0];
    if (!file) return;

    // Check file type
    if (!file.type.startsWith('image/')) {
      setError(`${type} must be an image file`);
      return;
    }

    // Check file size (2MB = 2 * 1024 * 1024 bytes)
    if (file.size > 2 * 1024 * 1024) {
      setError(`${type} must be less than 2MB`);
      return;
    }

    setUser((prevUser) => ({ ...prevUser, [`${type}File`]: file }));
  };

  const handleImageUpload = async (file, type) => {
    if (!file) return;

    const formData = new FormData();
    formData.append("image", file);

    try {
      const response = await post(UPLOAD_IMAGE, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response && response.filename) {
        setUser((prevUser) => ({ ...prevUser, [type]: response.filename }));
        setPreviews((prevPreviews) => ({ ...prevPreviews, [type]: URL.createObjectURL(file) }));
        setSuccess(`${type} uploaded successfully`);
      }
    } catch (err) {
      console.error(`Error uploading ${type}:`, err);
      setError(`Error uploading ${type}`);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await post(UPDATE_PROFILE, user);
      setSuccess("Profile updated successfully");
    } catch (err) {
      console.error("Error updating profile:", err);
      setError("Error updating profile");
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <Alert color="danger">{error}</Alert>;

  return (
    <Container fluid>
      <Row className="justify-content-center">
        <Col md={8}>
          <Card className="overflow-hidden">
            <div className="bg-primary p-4">
              <h5 className="text-white text-center mb-0">User Profile</h5>
            </div>
            <CardBody>
              <div className="text-center mb-4">
                <img
                  src={`${process.env.REACT_APP_API_URL}/image/files/${user.photo}`}
                  alt="Profile"
                  className="rounded-circle img-thumbnail"
                  style={{ width: "150px", height: "150px", objectFit: "cover" }}
                />
                <h4 className="mt-3">{user.name}</h4>
                <p className="text-muted">{user.role}</p>
                <FormGroup>
                  <Input type="file" name="photo" id="photo" accept="image/*" onChange={(e) => handleFileChange(e, 'photo')} />
                  <Button color="primary" onClick={() => handleImageUpload(user.photoFile, 'photo')} className="mt-2">
                    Upload Photo
                  </Button>
                </FormGroup>
              </div>
              {success && <Alert color="success">{success}</Alert>}
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="username">Username</Label>
                      <Input type="text" name="username" id="username" value={user.username} disabled />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="email">Email</Label>
                      <Input type="email" name="email" id="email" value={user.email} onChange={handleInputChange} disabled />
                    </FormGroup> 
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="name">Name</Label>
                      <Input type="text" name="name" id="name" value={user.name} onChange={handleInputChange} />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="contactNumber">Contact Number</Label>
                      <Input type="text" name="contactNumber" id="contactNumber" value={user.contactNumber} onChange={handleInputChange} />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Label for="postalAddress">Postal Address</Label>
                  <Input type="textarea" name="postalAddress" id="postalAddress" value={user.postalAddress} onChange={handleInputChange} />
                </FormGroup>
                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <Label for="city">City</Label>
                      <Input type="text" name="city" id="city" value={user.city} onChange={handleInputChange} />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for="state">State</Label>
                      <Input type="text" name="state" id="state" value={user.state} onChange={handleInputChange} />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for="pincode">Pincode</Label>
                      <Input type="text" name="pincode" id="pincode" value={user.pincode} onChange={handleInputChange} />
                    </FormGroup>
                  </Col>
                </Row>
                <FormGroup>
                  <Label for="UPI">UPI</Label>
                  <Input type="text" name="UPI" id="UPI" value={user.UPI} onChange={handleInputChange} />
                </FormGroup>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="bankName">Bank Name</Label>
                      <Input type="text" name="bankName" id="bankName" value={user.bankName} onChange={handleInputChange} />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="accountNumber">Account Number</Label>
                      <Input type="text" name="accountNumber" id="accountNumber" value={user.accountNumber} onChange={handleInputChange} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="ifscCode">IFSC Code</Label>
                      <Input type="text" name="ifscCode" id="ifscCode" value={user.ifscCode} onChange={handleInputChange} />
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="branch">Branch</Label>
                      <Input type="text" name="branch" id="branch" value={user.branch} onChange={handleInputChange} />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <Label for="aadharCardFront">Aadhar Front</Label>
                      <Input type="file" name="aadharCardFront" id="aadharCardFront" accept="image/*" onChange={(e) => handleFileChange(e, 'aadharCardFront')} />
                      <Button color="primary" onClick={() => handleImageUpload(user.aadharCardFrontFile, 'aadharCardFront')} className="mt-2">
                        Upload Aadhar Front
                      </Button>
                      {user.aadharCardFront && (
                        <img
                          src={`${process.env.REACT_APP_API_URL}/image/files/${user.aadharCardFront}`}
                          alt="Aadhar Front"
                          className="img-thumbnail mt-2"
                          style={{ maxWidth: '100%' }}
                        />
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for="aadharCardBack">Aadhar Back</Label>
                      <Input type="file" name="aadharCardBack" id="aadharCardBack" accept="image/*" onChange={(e) => handleFileChange(e, 'aadharCardBack')} />
                      <Button color="primary" onClick={() => handleImageUpload(user.aadharCardBackFile, 'aadharCardBack')} className="mt-2">
                        Upload Aadhar Back
                      </Button>
                      {user.aadharCardBack && (
                        <img
                          src={`${process.env.REACT_APP_API_URL}/image/files/${user.aadharCardBack}`}
                          alt="Aadhar Back"
                          className="img-thumbnail mt-2"
                          style={{ maxWidth: '100%' }}
                        />
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for="panCard">PAN Card</Label>
                      <Input type="file" name="panCard" id="panCard" accept="image/*" onChange={(e) => handleFileChange(e, 'panCard')} />
                      <Button color="primary" onClick={() => handleImageUpload(user.panCardFile, 'panCard')} className="mt-2">
                        Upload PAN Card
                      </Button>
                      {user.panCard && (
                        <img
                          src={`${process.env.REACT_APP_API_URL}/image/files/${user.panCard}`}
                          alt="PAN Card"
                          className="img-thumbnail mt-2"
                          style={{ maxWidth: '100%' }}
                        />
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Button color="primary" type="submit" block>
                  Update Profile
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default UserProfile;
