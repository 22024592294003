import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardBody, CardTitle, Row, Col, Spinner } from 'reactstrap';
import { get } from '../../helpers/api_helper';
import * as url from '../../helpers/url_helper';

const Course = () => {
    document.title = "Course Details";

    const { id } = useParams();
    const [course, setCourse] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchCourse = async () => {
            try {
                const response = await get(`${url.GET_SINGLE_COURSE}/${id}`);
                setCourse(response);
            } catch (error) {
                console.error('Error fetching course:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchCourse();
    }, [id]);

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Spinner color="primary" />
            </div>
        );
    }

    if (!course) {
        return <div>Course not found</div>;
    }

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle className="h4">Course Details</CardTitle>
                            <div>
                                <p><strong>Course Name:</strong> {course.coursename}</p>
                                <p><strong>Duration:</strong> {course.duration} days</p>
                                <p><strong>Price:</strong> ₹{course.price}</p>
                                <p><strong>Commission:</strong> ₹{course.commissionValue}</p>
                                <p><strong>Renewal Commission:</strong> ₹{course.renewalCommissionValue}</p>
                                {/* <p><strong>Status:</strong> {course.ishidden ? 'Hidden' : 'Visible'}</p> */}
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default Course;
