import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { Row, Col } from "reactstrap"
import { motion } from "framer-motion"

// Pages Components
import Miniwidget from "./Miniwidget"
import MonthlyEarnings from "./montly-earnings"
import YearlySales from "./yearly-sales"
import LatestOrders from "./latest-orders"

// Import API helper
import { get } from "helpers/api_helper"
import * as url from "../../helpers/url_helper"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"

const Dashboard = props => {
  document.title = "Dashboard"

  const [reports, setReports] = useState([])
  const [monthlyRevenue, setMonthlyRevenue] = useState(0)
  const [yearlyRevenue, setYearlyRevenue] = useState(0)
  const [projectedEarnings, setProjectedEarnings] = useState(0)
  const [latestOrders, setLatestOrders] = useState([])

  const breadcrumbItems = [{ title: "Dashboard", link: "#" }]

  useEffect(() => {
    props.setBreadcrumbItems("Dashboard", breadcrumbItems)
    fetchDashboardData()
  }, [])

  const fetchDashboardData = async () => {
    try {
      const response = await get(url.GET_EARNING)
      if (response && response.stats) {
        const { stats, latestOrders } = response

        setReports([
          {
            title: "Interested Leads",
            iconClass: "cube-outline",
            total: stats.interestCount.toString(),
            average: "+11%",
            badgecolor: "info",
          },
          {
            title: "Completed Orders",
            iconClass: "buffer",
            total: stats.completedOrderCount.toString(),
            average: "-29%",
            badgecolor: "danger",
          },
          {
            title: "Pending Orders",
            iconClass: "tag-text-outline",
            total: stats.pendingOrderCount.toString(),
            average: "0%",
            badgecolor: "warning",
          },
          {
            title: "Total Sales",
            iconClass: "briefcase-check",
            total: `${stats.totalSales.toFixed(2)}`,
            average: "+89%",
            badgecolor: "info",
          },
        ])

        setMonthlyRevenue(stats.monthlyCompletedSales)
        setYearlyRevenue(stats.yearlyCompletedSales)
        setProjectedEarnings(stats.projectedEarnings)

        // Set latest orders
        setLatestOrders(latestOrders)
      }
    } catch (error) {
      console.error("Error fetching dashboard data:", error)
    }
  }

  return (
    <React.Fragment>
      {/*mimi widgets */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <Miniwidget reports={reports} />
      </motion.div>

      <Row>
        <Col xl="6">
          {/* Monthly Earnings */}
          <Row>
            <Col>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <MonthlyEarnings monthlyRevenue={monthlyRevenue} />
              </motion.div>
            </Col>
            <Col>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <MonthlyEarnings
                  monthlyRevenue={projectedEarnings}
                  name="Projected Earnings"
                />
              </motion.div>
            </Col>
          </Row>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <YearlySales yearlyRevenue={yearlyRevenue} />
          </motion.div>
        </Col>

        <Col xl="6">
          {/* Projected Earnings */}
          {/* latest orders */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <LatestOrders orders={latestOrders} name="projected earning" />
          </motion.div>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Dashboard)
