import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Button, Table, Alert, ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { get, post } from 'helpers/api_helper'
import * as url from "../../helpers/url_helper"
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../store/actions";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { motion } from 'framer-motion';

const OrderAdd = (props) => {
    document.title = "Create Order"

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showLeadInfo, setShowLeadInfo] = useState(false);
    const [uniqueId, setUniqueId] = useState(null);
    const [leadType, setLeadType] = useState('existing');
    const [searchResult, setSearchResult] = useState(null);
    const [modal, setModal] = useState(false);
    const [orderDetails, setOrderDetails] = useState(null);
    const [copied, setCopied] = useState(false);
    const [cart, setCart] = useState([]);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [uniqueCourseIds, setUniqueCourseIds] = useState([]);

    useEffect(() => {
        const breadcrumbItems = [
            { title: "Orders", link: "#" },
            { title: "Create Order", link: "#" },
        ];
        props.setBreadcrumbItems('Orders', breadcrumbItems);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await get(url.GET_COURSES);
                setData(response.data || response);
            } catch (error) {
                console.error('Error fetching data:', error);
                setError('Failed to fetch courses. Please try again.');
            } finally {
                setLoading(false);
            }
        }

        fetchData();
    }, []);

    const validation = useFormik({
        initialValues: {
            courseId: '',
            searchUser: '',
            leadId: '',
            leadName: '',
            phoneNumber: '',
            email: '',
            course: '',
            price: '',
            duration: '',
            commissionValue: '',
        },
        validationSchema: Yup.object({
            searchUser: Yup.string().when('leadType', {
                is: 'existing',
                then: Yup.string().required('Search field is required'),
            }),
            courseId: Yup.string().required('Course id is required'),
            leadId: Yup.string().when('leadType', {
                is: 'existing',
                then: Yup.string().required('Lead id is required'),
            }),
            leadName: Yup.string().required('Lead name is required'),
            phoneNumber: Yup.string().required('Phone number is required'),
            email: Yup.string().email('Invalid email').required('Email is required'),
            course: Yup.string().required('Course is required'),
            price: Yup.number().required('Price is required'),
            duration: Yup.string().required('Duration is required'),
            commissionValue: Yup.number().required('Commission is required'),
        }),
        onSubmit: (values) => {
            const newCartItem = {
                courseId: values.courseId,
                course: values.course,
                duration: values.duration,
                price: values.price,
                commissionValue: values.commissionValue,
            }
            
            // Check if the course is already in the cart
            const courseExists = cart.some(item => item.courseId === newCartItem.courseId);
            
            if (courseExists) {
                setError('This course is already in the cart. You can only add a course once.');
            } else {
                setCart(prevCart => [...prevCart, newCartItem]);
                setError('');
                
                validation.setValues({
                    ...validation.values,
                    courseId: '',
                    course: '',
                    price: '',
                    duration: '',
                    commissionValue: '',
                });
            }
        },
    })

    const handleSearch = async (e) => {
        e.preventDefault();
        setError('');
        try {
            const response = await get(`${url.GET_LEAD_BY_SEARCH}?search=${validation.values.searchUser}`);
            
            if (response && response.name) {
                setSearchResult(response);
                validation.setValues({
                    ...validation.values,
                    leadId: response.id,
                    leadName: response.name,
                    phoneNumber: response.phoneNumber,
                    email: response.email,
                });
                setShowLeadInfo(true);
                setUniqueCourseIds(response.uniqueCourseIds || []);
            } else {
                throw new Error('Lead not found');
            }
        } catch (error) {
            console.error('Error searching for lead:', error);
            setError('Lead not found. Please try again or create a new lead.');
            setShowLeadInfo(false);
            setSearchResult(null);
            setUniqueCourseIds([]);
            validation.setValues({
                ...validation.values,
                leadId: '',
                leadName: '',
                phoneNumber: '',
                email: '',
            });
        }
    };

    const handleCourseChange = (e) => {
        const selectedCourse = data.find(course => course.coursename === e.target.value);
        if (selectedCourse) {
            validation.setValues({
                ...validation.values,
                courseId: selectedCourse.id,
                course: selectedCourse.coursename,
                price: selectedCourse.price,
                duration: selectedCourse.duration,
                commissionValue: selectedCourse.commissionValue
            });
        }
    }

    const handlePlaceOrder = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess(''); 
       
        try {
            if (cart.length === 0) {
                setError('Please add at least one course to the cart before placing an order.');
                return;
            }

            const orderData = {
                leadId: validation.values.leadId,
                leadName: validation.values.leadName,
                email: validation.values.email,
                phoneNumber: validation.values.phoneNumber,
                total: cart.reduce((sum, item) => sum + item.price, 0),
                commission: cart.reduce((sum, item) => sum + item.commissionValue, 0),
                courseDetails: cart.map(item => ({
                    courseId: parseInt(item.courseId),
                    expiryDate: new Date(new Date().setMonth(new Date().getMonth() + parseInt(item.duration))).toISOString().slice(0, 19) + 'Z'
                })),
                orderType: "new"
            };

            const response = await post(url.ADD_ORDER, orderData);
            console.log(response);
            console.log(response.status);
            console.log(response.message);
            if (response && response.status && response.status === "success") {
                setOrderDetails({
                    uniqueId: response.id,
                    verificationCode: response.uniquetag,
                    leadName: response.leadName,
                });
                setModal(true);
                
                validation.resetForm();
                setShowLeadInfo(false);
                setCart([]);
                setError('');
                setSuccess('Order created successfully!'); 
                setUniqueId(null);
                setSearchResult(null);
                setUniqueCourseIds([]);
            } else {
                setError(response.message || 'An error occurred while creating the order.');
            }
        } catch (error) {
            console.error('Error creating order:', error);
            setError(error.response?.data?.message || error.message || 'An unexpected error occurred. Please try again.');
        }
    }

    const handleReset = () => {
        validation.resetForm();
        setSearchResult(null);
        setError('');
        setSuccess('');
        setUniqueCourseIds([]);
    };

    const toggle = () => setModal(!modal);

    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    const handleRemoveFromCart = (index) => {
        setCart(prevCart => prevCart.filter((_, i) => i !== index));
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <React.Fragment>
            <motion.div 
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="page-content"
            >
                <Container fluid>
                    {success && <Alert color="success">{success}</Alert>}
                    {error && <Alert color="danger">{error}</Alert>}
                    <Card>
                        <CardBody>
                            <h4 className="card-title mb-4">Add New Order</h4>
                            <ButtonGroup className="mb-3">
                                <Button 
                                    color={leadType === 'existing' ? 'primary' : 'secondary'} 
                                    onClick={() => {
                                        setLeadType('existing');
                                        handleReset();
                                    }}
                                >
                                    Existing Lead
                                </Button>
                                <Button 
                                    color={leadType === 'new' ? 'primary' : 'secondary'} 
                                    onClick={() => {
                                        setLeadType('new');
                                        handleReset();
                                    }}
                                >
                                    New Order / Lead
                                </Button>
                            </ButtonGroup>
                            <Form onSubmit={validation.handleSubmit}>
                                {leadType === 'existing' && (
                                    <Row className="mb-3">
                                        <Col md={6}>
                                            <FormGroup>
                                                <Label for="searchUser">Search lead by phone or email</Label>
                                                <Input
                                                    type='text'
                                                    id="searchUser"
                                                    name="searchUser"
                                                    placeholder="email / phone"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.searchUser}
                                                    invalid={validation.touched.searchUser && validation.errors.searchUser}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={6}>
                                            <Button color="info" onClick={handleSearch} className="mt-4">Search</Button>
                                        </Col>
                                    </Row>
                                )}
                                {((leadType === 'existing' && searchResult) || leadType === 'new') && (
                                    <Row className="mb-3">
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="leadName">Lead Name</Label>
                                                <Input
                                                    id="leadName"
                                                    name="leadName"
                                                    value={validation.values.leadName}
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    invalid={validation.touched.leadName && validation.errors.leadName}
                                                    readOnly={leadType === 'existing'}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="email">Email</Label>
                                                <Input
                                                    id="email"
                                                    name="email"
                                                    type="email"
                                                    value={validation.values.email}
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    invalid={validation.touched.email && validation.errors.email}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md={4}>
                                            <FormGroup>
                                                <Label for="phoneNumber">Phone Number</Label>
                                                <Input
                                                    id="phoneNumber"
                                                    name="phoneNumber"
                                                    value={validation.values.phoneNumber}
                                                    onChange={(e) => {
                                                        const value = e.target.value.replace(/\D/g, '').slice(0, 10);
                                                        validation.setFieldValue('phoneNumber', value);
                                                    }}
                                                    onBlur={validation.handleBlur}
                                                    invalid={validation.touched.phoneNumber && validation.errors.phoneNumber}
                                                    maxLength={10}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                )}
                                {((leadType === 'existing' && searchResult) || leadType === 'new') && (
                                    <Row className="mb-3">
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label for="course">Course</Label>
                                                <Input
                                                    id="course"
                                                    name="course"
                                                    type="select"
                                                    onChange={(e) => {
                                                        validation.handleChange(e);
                                                        handleCourseChange(e);
                                                    }}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.course}
                                                    invalid={validation.touched.course && validation.errors.course}
                                                >
                                                    <option value="">Select Course</option>
                                                    {data.filter(course => !uniqueCourseIds.includes(course.id)).map((course, index) => (
                                                        <option key={index} value={course.coursename}>{course.coursename}</option>
                                                    ))}
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label>Price</Label>
                                                <Input value={`${validation.values.price} ₹`} disabled />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label>Duration</Label>
                                                <Input value={`${validation.values.duration} months`} disabled />
                                            </FormGroup>
                                        </Col>
                                        <Col md={3}>
                                            <FormGroup>
                                                <Label>Commission</Label>
                                                <Input value={`${validation.values.commissionValue} `} disabled />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                )}
                                {((leadType === 'existing' && searchResult) || leadType === 'new') && (
                                    <Button color="primary" type="submit">
                                        Add to Cart
                                    </Button>
                                )}
                            </Form>
                            {cart.length > 0 && (
                                <motion.div
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <h4 className="mt-4">Cart Items</h4>
                                    <div className="table-responsive">
                                        <Table className="mt-2">
                                            <thead>
                                                <tr>
                                                    <th>Sr No.</th>
                                                    <th>Course Name</th>
                                                    <th>Duration</th>
                                                    <th>Price</th>
                                                    <th>Commission</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {cart.map((item, index) => (
                                                    <motion.tr 
                                                        key={index}
                                                        initial={{ opacity: 0 }}
                                                        animate={{ opacity: 1 }}
                                                        transition={{ duration: 0.3, delay: index * 0.1 }}
                                                    >
                                                        <td>{index + 1}</td>
                                                        <td>{item.course}</td>
                                                        <td>{item.duration} months</td>
                                                        <td>{item.price} ₹</td>
                                                        <td>{item.commissionValue} ₹</td>
                                                        <td>
                                                            <Button color="danger" size="sm" onClick={() => handleRemoveFromCart(index)}>
                                                                Remove
                                                            </Button>
                                                        </td>
                                                    </motion.tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                    <Button color="success" onClick={handlePlaceOrder} className="mt-3">
                                        Create Order
                                    </Button>
                                </motion.div>
                            )}
                        </CardBody>
                    </Card>
                </Container>
            </motion.div>
            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Order Confirmation</ModalHeader>
                <ModalBody>
                    <p>Hello {orderDetails?.leadName}👋,</p>
                    <p>Thank you for purchasing a course from True Trader. Your Order Number is {orderDetails?.uniqueId}. We hope you find the content valuable and gain new insights.</p>
                    <p>Please find your unique verification number below. Click the provided link, enter the verification number, complete your details, and proceed with the payment. Once the process is complete, you will have full access to the course.</p>
                    <p>Unique Verification code: {orderDetails?.verificationCode}</p>
                    <p>Verification link: <a href="https://mytruetrader.com/verify" target="_blank" rel="noopener noreferrer">truetrader.com/verify</a></p>
                </ModalBody>
                <ModalFooter>
                    <CopyToClipboard 
                        text={`Hello ${orderDetails?.leadName}👋,\n\nThank you for purchasing a course from True Trader. Your Order Number is ${orderDetails?.uniqueId}. We hope you find the content valuable and gain new insights.\n\nPlease find your unique verification number below. Click the provided link, enter the verification number, complete your details, and proceed with the payment. Once the process is complete, you will have full access to the course.\n\nUnique Verification code: ${orderDetails?.verificationCode}\nVerification link: https://tba.brokerbandhu.in/verify`}
                        onCopy={handleCopy}
                    >
                        <Button color="primary">
                            {copied ? 'Copied!' : 'Copy to Clipboard'}
                        </Button>
                    </CopyToClipboard>
                    <Button color="secondary" onClick={toggle}>Close</Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    )
}

export default connect(null, { setBreadcrumbItems })(OrderAdd)