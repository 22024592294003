import React, { useState, useEffect } from "react"
import { Container, Row, Col, Card, CardBody, Table, Button } from "reactstrap"
import { get, post } from "helpers/api_helper"
import * as url from "../../helpers/url_helper"
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { setBreadcrumbItems } from "../../store/actions";
const RequestPage = (props) => {
    const [requests, setRequests] = useState([])
    const [currentPage, setCurrentPage] = useState(1);

    const [loading, setLoading] = useState(true)
    const breadcrumbItems = [
        { title: "Requests", link: "#" }
    ];
    useEffect(() => {
        props.setBreadcrumbItems('TBA', breadcrumbItems);
    }, [currentPage]);

    useEffect(() => {
        get(`${url.GET_REQ_USR}`)
            .then(response => {
                setRequests(response.requests)
                setLoading(false)
            })
            .catch(error => {
                console.error(error)
            })
    }, [])

    const handleButtonClick = id => {
        post(`${url.APPROVE_REQUEST}/${id}`)
            .then(response => {
                console.log(response.data)
                // Refresh the requests list after approval
                get(`${url.GET_REQ_USR}`)
                    .then(response => {
                        setRequests(response.requests)
                    })
                    .catch(error => {
                        console.error("Error refreshing requests:", error)
                    })
            })
            .catch(error => {
                console.error(error)
            })
    }

    return (
        <Container>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <h1>Requests for leads</h1>
                            {loading ? (
                                <p>Loading...</p>
                            ) : (
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Name</th>
                                            <th>Username</th>
                                            <th>Email</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {requests.map(request => (
                                            <tr key={request.id}>
                                                <td>{request.id}</td>
                                                <td>{request.name}</td>
                                                <td>{request.username}</td>
                                                <td>{request.email}</td>
                                                <td>
                                                    <Button onClick={() => handleButtonClick(request.id)}>
                                                        Done
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            )}
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default connect(null, { setBreadcrumbItems })(RequestPage);