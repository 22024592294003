import React, { useEffect, useState } from 'react';
import { useParams,useLocation } from 'react-router-dom';
import { get, post } from "helpers/api_helper"
import * as url from "../../helpers/url_helper"
import { Container, Row, Col, Spinner } from 'reactstrap';
import {UPDATE_TRANSACTION_DETAILS} from "../../helpers/url_helper"
const PhonePayStatus = () => {
    const [paymentStatus, setPaymentStatus] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { merchantTransactionId, uniquetag } = useParams();

    useEffect(() => {
        const checkPaymentStatus = async () => {
            try {
                if (!merchantTransactionId) {
                    throw new Error('Transaction ID not found');
                }

                const response = await get(`${url.PAYMENT_STATUS}/${merchantTransactionId}`);
                
                if (response.code === "PAYMENT_SUCCESS") {

                    const updateResponse = await post(
                        url.UPDATE_TRANSACTION_DETAILS + `/${uniquetag}`,
                        {
                            transaction_id: merchantTransactionId,
                        }
                    );
                    if (updateResponse.message !== "success") {
                        throw new Error("Failed to update transaction details");
                    }
                    setPaymentStatus({
                        status: 'success',
                        transactionId: response.data.merchantTransactionId,
                        timestamp: response.data.paymentInstrument.paymentTime,
                        amount: response.data.amount / 100, // Convert from paise to rupees
                        uniquetag: uniquetag
                    });
                } else if (response.code === "PAYMENT_ERROR" || response.code === "PAYMENT_DECLINED") {
                    setPaymentStatus({
                        status: 'failed',
                        transactionId: response.data?.merchantTransactionId,
                        timestamp: response.data?.paymentInstrument?.paymentTime,
                        message: response.message,
                        uniquetag: uniquetag
                    });
                } else {
                    setPaymentStatus({
                        status: 'pending',
                        transactionId: merchantTransactionId,
                        uniquetag: uniquetag
                    });
                }
                setLoading(false);
            } catch (err) {
                console.error("Payment status error:", err);
                setError(err.message || 'Failed to fetch payment status');
                setLoading(false);
            }
        };

        checkPaymentStatus();
    }, [merchantTransactionId, uniquetag]);

    const getStatusIcon = (status) => {
        switch (status?.toLowerCase()) {
            case 'success':
                return <i className="fas fa-check-circle text-success fa-3x" />;
            case 'failed':
                return <i className="fas fa-times-circle text-danger fa-3x" />;
            case 'pending':
                return <i className="fas fa-clock text-warning fa-3x" />;
            default:
                return null;
        }
    };

    const getStatusColor = (status) => {
        switch (status?.toLowerCase()) {
            case 'success':
                return 'text-success';
            case 'failed':
                return 'text-danger';
            case 'pending':
                return 'text-warning';
            default:
                return 'text-dark';
        }
    };

    if (loading) {
        return (
            <Container className="mt-5">
                <Row className="justify-content-center align-items-center min-vh-60">
                    <Col className="text-center">
                        <Spinner color="primary" />
                        <p className="mt-3">Checking payment status...</p>
                    </Col>
                </Row>
            </Container>
        );
    }

    return (
        <Container className="mt-5">
            <Row className="justify-content-center">
                <Col sm={8} md={6}>
                    <div className="card p-4 text-center shadow">
                        {error ? (
                            <div>
                                <i className="fas fa-exclamation-triangle text-danger fa-3x mb-3" />
                                <h4 className="text-danger mb-3">Error</h4>
                                <p className="text-muted">{error}</p>
                            </div>
                        ) : (
                            <div>
                                {getStatusIcon(paymentStatus?.status)}
                                <h4 className={`${getStatusColor(paymentStatus?.status)} mt-4 mb-3`}>
                                    Payment {paymentStatus?.status}
                                </h4>
                                <p className="text-muted mb-2">
                                    Transaction ID: {paymentStatus?.transactionId}
                                </p>
                               
                                {paymentStatus?.amount && (
                                    <p className="text-muted mb-2">
                                        Amount: ₹{paymentStatus.amount}
                                    </p>
                                )}
                                {paymentStatus?.message && (
                                    <p className="text-danger mb-2">
                                        {paymentStatus.message}
                                    </p>
                                )}
                                {paymentStatus?.timestamp && (
                                    <p className="text-muted small">
                                        Time: {new Date(paymentStatus.timestamp).toLocaleString()}
                                    </p>
                                )}
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default PhonePayStatus;
