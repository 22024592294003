import React, { useState } from 'react'
import PropTypes from "prop-types";
import { Link } from 'react-router-dom';
import { Alert, Card, CardBody, Col, Container, FormFeedback, Input, Label, Row } from 'reactstrap';
import logoDark from "../../assets/images/logo-dark.png";
import logoLight from "../../assets/images/logo-light.png";
import withRouter from 'components/Common/withRouter';
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
import { FORGET_PASSWORD } from "helpers/url_helper";
import emailjs from '@emailjs/browser';
import { post } from 'helpers/api_helper';

const ForgetPasswordPage = props => {
  const [error, setError] = useState(null);
  const [successMsg, setSuccessMsg] = useState(null);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Must be a valid email")
        .required("Please Enter Your Email"),
    }),
    onSubmit: async (values) => {
      try {
        // First hit backend API to get reset token
        const response = await post(FORGET_PASSWORD, {
          email: values.email
        });

        if (response.status === 'ok') {
          // Send email using EmailJS
          console.log('Response from backend:', response);
          const emailParams = {
            user_name: response.username,
            reset_link: `https://tba.brokerbandhu.in/reset-password?token=${response.resetToken}`,
            tba_email: response.email,       
          };

          console.log('EmailJS parameters:', emailParams);
          console.log('EmailJS config:', {
            serviceId: process.env.REACT_APP_EMAILJS_SERVICE_ID,
            templateId: process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
            publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY
          });

          try {
            const emailResult = await emailjs.send(
              process.env.REACT_APP_EMAILJS_SERVICE_ID,
              process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
              emailParams,
              process.env.REACT_APP_EMAILJS_PUBLIC_KEY
            );
            console.log('EmailJS success response:', emailResult);

            setSuccessMsg("Password reset link have been sent to your email. The reset link will be valid for 24 hours.");
            setError(null);
          } catch (emailError) {
            console.error("EmailJS error details:", {
              message: emailError.message,
              name: emailError.name,
              stack: emailError.stack,
              text: emailError.text
            });
            setError("Failed to send email. Please try again later.");
          }
        }
      } catch (error) {
        console.error("API error details:", error);
        setError(error.response?.data?.message || "An error occurred while processing your request");
        setSuccessMsg(null);
      }
    }
  });

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <CardBody className="pt-0">
                  <h3 className="text-center mt-5 mb-4">

                  </h3>
                  <div className="p-3">
                    <h4 className="text-muted font-size-18 mb-3 text-center">Reset Password</h4>
                    {error && (
                      <Alert color="danger" style={{ marginTop: "13px" }}>
                        {error}
                      </Alert>
                    )}
                    {successMsg && (
                      <Alert color="success" style={{ marginTop: "13px" }}>
                        {successMsg}
                      </Alert>
                    )}
                    <form className="form-horizontal mt-4"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}>

                      <div className="mb-3">
                        <Label htmlFor="useremail">Email</Label>
                        <Input
                          name="email"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ""}
                          invalid={
                            validation.touched.email && validation.errors.email ? true : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                        ) : null}
                      </div>

                      <Row className="mb-3">
                        <div className="col-12 text-end">
                          <button className="btn btn-primary w-md waves-effect waves-light" type="submit">Reset</button>
                        </div>
                      </Row>
                    </form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>Remember It ? <Link to="/login" className="text-primary"> Sign In Here </Link> </p>
                © {new Date().getFullYear()} Lexa <span className="d-none d-sm-inline-block"> - Crafted with <i className="mdi mdi-heart text-danger"></i> by Agevole.</span>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default withRouter(ForgetPasswordPage);
