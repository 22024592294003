import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, CardBody, CardTitle, Row, Col, Spinner } from 'reactstrap';
import { get } from '../../helpers/api_helper';
import * as url from '../../helpers/url_helper';

const Lead = () => {
    document.title = "Lead Profile";

    const { id } = useParams();
    const [lead, setLead] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchLead = async () => {
            try {
                const response = await get(`${url.GET_LEAD_PROFILE}/${id}`);
                setLead(response);
            } catch (error) {
                console.error('Error fetching lead:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchLead();
    }, [id]);

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
                <Spinner color="primary" />
            </div>
        );
    }

    if (!lead) {
        return <div>Lead not found</div>;
    }

    return (
        <React.Fragment>
            <Row>
                <Col>
                    <Card>
                        <CardBody>
                            <CardTitle className="h4">Lead Profile</CardTitle>
                            <div>
                                <p><strong>Name:</strong> {lead.name}</p>
                                <p><strong>Phone Number:</strong> {lead.phoneNumber}</p>
                                <p><strong>Email:</strong> {lead.email}</p>
                                <p><strong>City:</strong> {lead.city}</p>
                                <p><strong>State:</strong> {lead.state}</p>
                                <p><strong>Interest:</strong> {lead.interest}</p>
                                <p><strong>Assigned TBA: </strong> {lead.AssignedTBA}</p>
                                {/* <p><strong>Orders:</strong></p>
                                {lead.Orders && lead.Orders.length > 0 ? (
                                    lead.Orders.map(order => (
                                        <div key={order.id} style={{ marginBottom: '10px' }}>
                                            <p><strong>Order ID:</strong> {order.id}</p>
                                            <p><strong>Status:</strong> {order.status}</p>
                                            <p><strong>Total:</strong> ${order.total}</p>
                                        </div>
                                    ))
                                ) : (
                                    <p>No orders available.</p>
                                )} */}
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default Lead;
