import React, { useEffect, useState } from 'react'
import { Row, Col, Card, CardBody, CardTitle, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import { get, put } from 'helpers/api_helper'
import * as url from "../../helpers/url_helper"
import { Link } from 'react-router-dom'
import { connect } from "react-redux"
import { setBreadcrumbItems } from "../../store/actions"
import { motion, AnimatePresence } from "framer-motion"
import { toast } from 'react-toastify'

const ListTBA = (props) => {
    document.title = "TBAs";

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [modal, setModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const breadcrumbItems = [
        { title: "TBA", link: "#" },
        { title: "List", link: "#" }
    ]

    useEffect(() => {
        props.setBreadcrumbItems('List TBA', breadcrumbItems)
        const fetchData = async () => {
            try {
                const response = await get(url.GET_TBAS);
                setData(response.users || []);
            } catch (error) {
                setError('Failed to fetch TBA data');
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        }

        fetchData();
    }, []);

    const toggleModal = () => setModal(!modal);

    const handleStatusChangeClick = (user) => {
        setSelectedUser(user);
        setModal(true);
    };

    const handleStatusChange = async () => {
        try {
            const newStatus = !selectedUser.isActive;
            await put(`${url.UPDATE_TBA_STATUS}/${selectedUser.id}`, { isActive: newStatus });
            
            setData(data.map(item => 
                item.id === selectedUser.id ? {...item, isActive: newStatus} : item
            ));

            setSuccess(`User ${newStatus ? 'activated' : 'deactivated'} successfully`);
            toast.success(`User ${newStatus ? 'activated' : 'deactivated'} successfully`);
            
            toggleModal();
        } catch (error) {
            setError('Failed to update user status');
            toast.error('Failed to update user status');
            console.error('Error updating status:', error);
        }
    };

    if (loading) {
        return (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="d-flex justify-content-center align-items-center h-100"
            >
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </motion.div>
        );
    }

    const getRoleBadgeColor = (role) => {
        switch(role?.toLowerCase()) {
            case 'admin': return 'bg-primary';
            case 'superadmin': return 'bg-warning';
            case 'staff': return 'bg-info';
            case 'tba': return 'bg-secondary';
            default: return 'bg-light text-dark';
        }
    }

    return (
        <React.Fragment>
            <AnimatePresence>
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    transition={{ duration: 0.5 }}
                >
                    <Row>
                        <Col>
                            <Card className="shadow">
                                <CardBody>
                                    <CardTitle className="h4 mb-4">TBA Management</CardTitle>
                                    {error && <div className="alert alert-danger">{error}</div>}
                                    {success && <div className="alert alert-success">{success}</div>}
                                    <div className="table-rep-plugin">
                                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                                            <Table id="tech-companies-1" className="table table-hover table-striped table-bordered">
                                                <Thead className="bg-light">
                                                    <Tr>
                                                        <Th className="fw-bold">ID</Th>
                                                        <Th className="fw-bold">Username</Th>
                                                        <Th data-priority="1" className="fw-bold">Email</Th>
                                                        <Th data-priority="1" className="fw-bold">Contact Number</Th>
                                                        <Th data-priority="3" className="fw-bold text-center">Role</Th>
                                                        <Th data-priority="1" className="fw-bold text-center">Name</Th>
                                                        <Th data-priority="1" className="fw-bold text-center">Status</Th>
                                                        <Th data-priority="1" className="fw-bold text-center">Action</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {data.map((item) => (
                                                        <motion.tr
                                                            key={item.id}
                                                            initial={{ opacity: 0 }}
                                                            animate={{ opacity: 1 }}
                                                            exit={{ opacity: 0 }}
                                                            whileHover={{ scale: 1.01 }}
                                                            className={item.isActive ? 'table-row-active' : 'table-row-inactive'}
                                                        >
                                                            <Td>{item.id}</Td>
                                                            <Th>
                                                                <Link to={`/tba/${item.id}`} className="text-primary">
                                                                    <span className="co-name fw-medium">{item.username}</span>
                                                                    <i className="fas fa-external-link-alt ms-1"></i>
                                                                </Link>
                                                            </Th>
                                                            <Td>{item.email}</Td>
                                                            <Td>{item.contactNumber || "N/A"}</Td>
                                                            <Td className="text-center">
                                                                <motion.span 
                                                                    whileHover={{ scale: 1.1 }}
                                                                    className={`badge ${getRoleBadgeColor(item.role)} fw-medium px-3 py-2`}
                                                                >
                                                                    {item.role || "N/A"}
                                                                </motion.span>
                                                            </Td>
                                                            <Td className="text-center">{item.name || "N/A"}</Td>
                                                            <Td className="text-center">
                                                                <motion.span
                                                                    whileHover={{ scale: 1.1 }}
                                                                    className={`badge rounded-pill px-3 py-2 ${item.isActive ? 'bg-soft-success text-success' : 'bg-soft-danger text-danger'}`}
                                                                    style={{fontSize: '0.85rem', fontWeight: '500'}}
                                                                >
                                                                    {item.isActive ? 'Active' : 'Inactive'}
                                                                </motion.span>
                                                            </Td>
                                                            <Td className="text-center">
                                                                <motion.div
                                                                    whileHover={{ scale: 1.05 }}
                                                                    whileTap={{ scale: 0.95 }}
                                                                >
                                                                    <Button
                                                                        color={item.isActive ? 'soft-danger' : 'soft-success'}
                                                                        size="sm"
                                                                        className="rounded-pill px-3 py-2 shadow-sm"
                                                                        style={{minWidth: '120px'}}
                                                                        onClick={() => handleStatusChangeClick(item)}
                                                                    >
                                                                        <i className={`fas ${item.isActive ? 'fa-user-times' : 'fa-user-check'} me-2`}></i>
                                                                        {item.isActive ? 'Deactivate' : 'Activate'}
                                                                    </Button>
                                                                </motion.div>
                                                            </Td>
                                                        </motion.tr>
                                                    ))}
                                                </Tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Modal isOpen={modal} toggle={toggleModal}>
                        <ModalHeader toggle={toggleModal}>Confirm Action</ModalHeader>
                        <ModalBody>
                            Are you sure you want to {selectedUser?.isActive ? 'deactivate' : 'activate'} {selectedUser?.username}?
                        </ModalBody>
                        <ModalFooter>
                            <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                            <Button color={selectedUser?.isActive ? 'danger' : 'success'} onClick={handleStatusChange}>
                                Confirm
                            </Button>
                        </ModalFooter>
                    </Modal>
                </motion.div>
            </AnimatePresence>
        </React.Fragment>
    );
}

export default connect(null, { setBreadcrumbItems })(ListTBA);
