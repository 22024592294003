import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Button, Alert, InputGroupText } from 'reactstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { post } from 'helpers/api_helper';
import * as url from "../../helpers/url_helper";
const Reset = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [loading, setLoading] = useState(false);

    const location = useLocation();
    const navigate = useNavigate();
    const token = new URLSearchParams(location.search).get('token');

    useEffect(() => {
        if (!token) {
            navigate('/login');
        }
    }, [token, navigate]);

    const togglePasswordVisibility = (field) => {
        if (field === 'password') {
            setShowPassword(!showPassword);
        } else {
            setShowConfirmPassword(!showConfirmPassword);
        }
    };

    const validatePassword = () => {
        if (newPassword.length < 8) {
            setError('Password must be at least 8 characters long');
            return false;
        }
        if (newPassword !== confirmPassword) {
            setError('Passwords do not match');
            return false;
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        if (!validatePassword()) {
            return;
        }

        setLoading(true);
        try {
            const response = await post(url.RESET_PASSWORD, {
                token,
                newPassword
            });
            
            setSuccess('Password reset successful! Login with new id and password');
            // setTimeout(() => {
            //     navigate('/login');
            // }, 3000);
        } catch (error) {
            setError(error.response?.data?.message || 'Failed to reset password. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="account-pages my-5">
            <Container>
                <Row className="justify-content-center">
                    <Col md={8} lg={6} xl={5}>
                        <Card>
                            <CardBody className="p-4">
                                <div className="text-center mb-4">
                                    <h4>Reset Password</h4>
                                    <p className="text-muted">Enter your new password below</p>
                                </div>

                                {error && <Alert color="danger">{error}</Alert>}
                                {success && <Alert color="success">{success}</Alert>}

                                <Form onSubmit={handleSubmit}>
                                    <FormGroup className="mb-3">
                                        <Label for="newPassword">New Password</Label>
                                        <div className="input-group">
                                            <Input
                                                type={showPassword ? "text" : "password"}
                                                id="newPassword"
                                                value={newPassword}
                                                onChange={(e) => setNewPassword(e.target.value)}
                                                placeholder="Enter new password"
                                                required
                                            />
                                            <InputGroupText style={{cursor: 'pointer'}} onClick={() => togglePasswordVisibility('password')}>
                                                <i className={`mdi ${showPassword ? 'mdi-eye-off' : 'mdi-eye'}`}></i>
                                            </InputGroupText>
                                        </div>
                                    </FormGroup>

                                    <FormGroup className="mb-4">
                                        <Label for="confirmPassword">Confirm New Password</Label>
                                        <div className="input-group">
                                            <Input
                                                type={showConfirmPassword ? "text" : "password"}
                                                id="confirmPassword"
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                placeholder="Confirm new password"
                                                required
                                            />
                                            <InputGroupText style={{cursor: 'pointer'}} onClick={() => togglePasswordVisibility('confirm')}>
                                                <i className={`mdi ${showConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'}`}></i>
                                            </InputGroupText>
                                        </div>
                                    </FormGroup>

                                    <div className="d-grid">
                                        <Button
                                            color="primary"
                                            type="submit"
                                            disabled={loading}
                                        >
                                            {loading ? 'Updating...' : 'Update Password'}
                                        </Button>
                                    </div>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Reset;