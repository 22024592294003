import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Card, CardBody, Form, FormGroup, Label, Input, Button, Table, Alert, Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import { get, post, put } from 'helpers/api_helper'
import * as url from "../../helpers/url_helper"
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../store/actions";
import { motion, AnimatePresence } from "framer-motion";

const TransferTBA = (props) => {
    document.title = "Transfer TBA"

    const [sourceTBA, setSourceTBA] = useState('')
    const [destinationTBA, setDestinationTBA] = useState('')
    const [tbas, setTbas] = useState([])
    const [leads, setLeads] = useState([])
    const [selectedLeads, setSelectedLeads] = useState([])
    const [loading, setLoading] = useState(false)
    const [searchLoading, setSearchLoading] = useState(false)
    const [transferLoading, setTransferLoading] = useState(false)
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('')
    const [searchPerformed, setSearchPerformed] = useState(false)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    const [totalItems, setTotalItems] = useState(0)
    const pageSize = 50 // Number of items per page
    const [transferType, setTransferType] = useState('allocate') // Default transfer type is allocate

    const breadcrumbItems = [
        { title: "Leads", link: "#" },
        { title: "Transfer Lead", link: "#" }
    ];

    useEffect(() => {
        props.setBreadcrumbItems('Transfer Lead', breadcrumbItems);

        const fetchTBAs = async () => {
            setLoading(true)
            try {
                const response = await get(url.GET_TBAS)
                setTbas(response.users || []);
            } catch (error) {
                console.error('Error fetching TBAs:', error)
                setError('Failed to fetch TBAs')
            } finally {
                setLoading(false)
            }
        }

        fetchTBAs()
    }, [])

    const handleSourceTBASearch = async () => {
        setSearchLoading(true)
        setError('')
        setSearchPerformed(true)
        try {
            const response = await get(`${url.GET_LEAD_OF_TBA}/${sourceTBA}?page=${currentPage}&pageSize=${pageSize}`)
            setLeads(response.data || [])
            setTotalPages(response.totalPages || 1)
            setTotalItems(response.totalItems || 0)
            setSelectedLeads([])
        } catch (error) {
            console.error('Error fetching leads:', error)
            setError('Failed to fetch leads for the selected TBA')
            setLeads([])
        } finally {
            setSearchLoading(false)
        }
    }

    useEffect(() => {
        if (searchPerformed) {
            handleSourceTBASearch()
        }
    }, [currentPage])

    const handleLeadSelect = (leadId) => {
        setSelectedLeads(prevSelected =>
            prevSelected.includes(leadId)
                ? prevSelected.filter(id => id !== leadId)
                : [...prevSelected, leadId]
        )
    }

    const handleSelectAll = () => {
        if (selectedLeads.length === leads.length) {
            setSelectedLeads([])
        } else {
            setSelectedLeads(leads.map(lead => lead.id))
        }
    }

    const handleTransfer = async () => {
        if (!sourceTBA) {
            setError('Please select the source TBA')
            return
        }

        if (selectedLeads.length === 0) {
            setError('Please select at least one lead to transfer')
            return
        }

        setTransferLoading(true)
        setError('')
        setSuccess('')

        try {
            let transferUrl = transferType === 'allocate' ? url.TRANSFER_LEAD : url.DEALLOCATE_LEAD;
            if (transferType === 'allocate') {
                if (!destinationTBA) {
                    setError('Please select the destination TBA')
                    return
                }
                if (sourceTBA === destinationTBA) {
                    setError('Source and destination TBA cannot be the same')
                    return
                }
                await put(transferUrl, { 
                    sourceTbaId: sourceTBA, 
                    destinationTbaId: destinationTBA,
                    leadIds: selectedLeads
                })
            } else {
                await put(transferUrl, { 
                    sourceTbaId: sourceTBA,
                    leadIds: selectedLeads
                })
            }
            setSuccess('Leads transferred successfully')
            setLeads([])
            setSelectedLeads([])
            setCurrentPage(1) // Reset to first page
            handleSourceTBASearch() // Refresh the lead list after transfer
        } catch (error) {
            console.error('Error transferring leads:', error)
            setError('Failed to transfer leads')
        } finally {
            setTransferLoading(false)
        }
    }

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <AnimatePresence>
                        {success && (
                            <motion.div
                                initial={{ opacity: 0, y: -50 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -50 }}
                            >
                                <Alert color="success">{success}</Alert>
                            </motion.div>
                        )}
                        {error && (
                            <motion.div
                                initial={{ opacity: 0, y: -50 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -50 }}
                            >
                                <Alert color="danger">{error}</Alert>
                            </motion.div>
                        )}
                    </AnimatePresence>
                    <Card>
                        <CardBody>
                            <h4 className="card-title mb-4">Transfer TBA</h4>
                            <Form>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label for="transferType">Transfer Type</Label>
                                            <Input
                                                type="select"
                                                id="transferType"
                                                value={transferType}
                                                onChange={(e) => setTransferType(e.target.value)}
                                            >
                                                <option value="allocate">Allocate</option>
                                                <option value="deallocate">Deallocate</option>
                                            </Input>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="sourceTBA">Source TBA</Label>
                                            <Input
                                                type="select"
                                                id="sourceTBA"
                                                value={sourceTBA}
                                                onChange={(e) => setSourceTBA(e.target.value)}
                                            >
                                                <option value="">Select Source TBA</option>
                                                {tbas.map((tba) => (
                                                    <option key={tba.id} value={tba.id}>
                                                        {tba.username}
                                                    </option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                        <motion.div
                                            whileHover={{ scale: 1.05 }}
                                            whileTap={{ scale: 0.95 }}
                                        >
                                            <Button color="primary" onClick={handleSourceTBASearch} disabled={searchLoading}>
                                                {searchLoading ? (
                                                    <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                ) : (
                                                    'Search Leads'
                                                )}
                                            </Button>
                                        </motion.div>
                                    </Col>
                                    <Col md={6} style={{ marginTop: '105px' }}>
                                        <FormGroup>
                                            <Input
                                                type="select"
                                                id="destinationTBA"
                                                value={destinationTBA}
                                                onChange={(e) => setDestinationTBA(e.target.value)}
                                                disabled={transferType === 'deallocate'}
                                                style={{ display: transferType === 'deallocate' ? 'none' : 'block' }}
                                            >
                                                <option value="">Select Destination TBA</option>
                                                {transferType === 'allocate' && tbas.map((tba) => (
                                                    <option key={tba.id} value={tba.id}>
                                                        {tba.username}
                                                    </option>
                                                ))}
                                            </Input>
                                        </FormGroup>
                                        <motion.div
                                            whileHover={{ scale: 1.05 }}
                                            whileTap={{ scale: 0.95 }}
                                        >
                                            <Button color="success" onClick={handleTransfer} disabled={transferLoading || selectedLeads.length === 0}>
                                                {transferLoading ? (
                                                    <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span>
                                                ) : (
                                                    transferType === 'allocate' ? 'Transfer Selected Leads' : 'Deallocate Selected Leads'
                                                )}
                                            </Button>
                                        </motion.div>
                                    </Col>
                                </Row>
                            </Form>
                            {loading && (
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    className="text-center mt-4"
                                >
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </motion.div>
                            )}
                            {searchPerformed && leads.length === 0 && !searchLoading && (
                                <motion.p
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    className="mt-4"
                                >
                                    No leads assigned to this TBA.
                                </motion.p>
                            )}
                            {leads.length > 0 && (
                                <motion.div
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    className="mt-4"
                                >
                                    <div className="d-flex align-items-center mb-3">
                                        <Input
                                            type="checkbox"
                                            id="selectAll"
                                            onChange={handleSelectAll}
                                            checked={selectedLeads.length === leads.length && leads.length > 0}
                                            style={{ width: '20px', height: '20px' }}
                                        />
                                        <Label for="selectAll" className="mb-0 ml-2">
                                            {selectedLeads.length === leads.length ? 'Deselect All' : 'Select All'}
                                        </Label>
                                    </div>
                                    <div className="table-responsive">
                                        <Table>
                                            <thead>
                                                <tr>
                                                    <th>Select</th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Phone</th>
                                                    <th>City</th>
                                                    <th>State</th>
                                                    <th>Interest</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {leads.map((lead) => (
                                                    <motion.tr
                                                        key={lead.id}
                                                        initial={{ opacity: 0 }}
                                                        animate={{ opacity: 1 }}
                                                        transition={{ duration: 0.3 }}
                                                    >
                                                        <td>
                                                            <Input
                                                                type="checkbox"
                                                                checked={selectedLeads.includes(lead.id)}
                                                                onChange={() => handleLeadSelect(lead.id)}
                                                            />
                                                        </td>
                                                        <td>{lead.name}</td>
                                                        <td>{lead.email}</td>
                                                        <td>{lead.phoneNumber}</td>
                                                        <td>{lead.city}</td>
                                                        <td>{lead.state}</td>
                                                        <td>{lead.interest}</td>
                                                    </motion.tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                    {totalPages > 1 && (
                                        <div className="d-flex justify-content-center mt-3">
                                            <Pagination>
                                                <PaginationItem disabled={currentPage === 1}>
                                                    <PaginationLink previous onClick={() => handlePageChange(currentPage - 1)} />
                                                </PaginationItem>
                                                {[...Array(totalPages)].map((_, i) => (
                                                    <PaginationItem key={i} active={i + 1 === currentPage}>
                                                        <PaginationLink onClick={() => handlePageChange(i + 1)}>
                                                            {i + 1}
                                                        </PaginationLink>
                                                    </PaginationItem>
                                                ))}
                                                <PaginationItem disabled={currentPage === totalPages}>
                                                    <PaginationLink next onClick={() => handlePageChange(currentPage + 1)} />
                                                </PaginationItem>
                                            </Pagination>
                                        </div>
                                    )}
                                </motion.div>
                            )}
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default connect(null, { setBreadcrumbItems })(TransferTBA);
