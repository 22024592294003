import React, { useEffect, useState } from "react"
import * as url from "../../helpers/url_helper"
import { post } from "helpers/api_helper";
import { Alert, Col, Row, Card, CardBody } from "reactstrap";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../store/actions";
const CourseAdd = (props) => {
    const [courseName, setCourseName] = useState("");
    const [pricing, setPricing] = useState([{ duration: "", price: "", commissionType: "percentage", commissionValue: "", renewalCommissionType: "percentage", renewalCommissionValue: "" }]);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    useEffect(() => {
        const breadcrumbItems = [
            { title: "Courses", link: "#" },
            { title: "Add Course", link: "#" },
        ];
        props.setBreadcrumbItems('Courses', breadcrumbItems);
    }, []);
    const handleCourseNameChange = (e) => {
        setCourseName(e.target.value);
    };

    const handlePricingChange = (index, e) => {
        const { name, value } = e.target;
        const newPricing = [...pricing];
        if (name === "duration" || name === "price") {
            newPricing[index][name] = Number(value);
        } else {
            newPricing[index][name] = value;
        }
        setPricing(newPricing);
    };

    const handleAddPricing = () => {
        setPricing([...pricing, { duration: "", price: "", commissionType: "percentage", commissionValue: "", renewalCommissionType: "percentage", renewalCommissionValue: "" }]);
    };

    const handleRemovePricing = (index) => {
        const newPricing = pricing.filter((_, i) => i !== index);
        setPricing(newPricing);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSuccess('');
        setError('');
        
        // Check if all fields are filled
        if (!courseName.trim()) {
            setError('Course name is required.');
            return;
        }
        
        for (let item of pricing) {
            if (!item.duration || !item.price || !item.commissionValue || !item.renewalCommissionValue) {
                setError('All pricing fields are required.');
                return;
            }
        }
        
        const formData = {
            coursename: courseName,
            pricingInfo: pricing.map(item => ({
                ...item,
                commissionValue: item.commissionValue,
                calculatedCommission: item.commissionType === "percentage"
                    ? (item.price * Number(item.commissionValue) / 100).toFixed(2)
                    : Number(item.commissionValue),
                renewalCommissionValue: item.renewalCommissionValue,
                calculatedRenewalCommission: item.renewalCommissionType === "percentage"
                    ? (item.price * Number(item.renewalCommissionValue) / 100).toFixed(2)
                    : Number(item.renewalCommissionValue)
            }))
        };
        try {
            const response = await post(url.ADD_COURSE, formData);

            if (response.message === "success") {
                // Clear all fields
                setCourseName("");
                setPricing([{ duration: "", price: "", commissionType: "percentage", commissionValue: "", renewalCommissionType: "percentage", renewalCommissionValue: "" }]);
                setSuccess("Course added successfully");
            }
        } catch (error) {
            setError(error.message);
        }
    };

    const calculateCommissionPrice = (price, commissionValue, commissionType) => {
        if (commissionType === "percentage") {
            return Math.floor(price * Number(commissionValue) / 100);
        }
        return Math.floor(Number(commissionValue));
    };

    return (
        <React.Fragment>
            <Card className="mt-4">
                <CardBody className="p-4">
                    <h5 className="font-size-16 mb-4">
                        <i className="mdi mdi-arrow-right text-primary me-1"></i> Add course
                    </h5>

                    {success && <Alert color="success" className="mb-4">{success}</Alert>}
                    {error && <Alert color="danger" className="mb-4">{error}</Alert>}

                    <form onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label htmlFor="horizontal-firstname-input" className="form-label">Course name</label>
                            <input
                                required
                                type="text"
                                className="form-control"
                                id="horizontal-firstname-input"
                                placeholder="Enter course name"
                                value={courseName}
                                onChange={handleCourseNameChange}
                            />
                        </div>
                        {pricing.map((price, index) => (
                            <Card key={index} className="mb-4">
                                <CardBody>
                                    <h6 className="mb-3">Pricing {index + 1}</h6>
                                    <Row>
                                        <Col md={6} className="mb-3">
                                            <input
                                                required
                                                type="number"
                                                className="form-control"
                                                id={`duration-${index}`}
                                                name="duration"
                                                placeholder="Duration (in months)"
                                                value={price.duration}
                                                onChange={(e) => handlePricingChange(index, e)}
                                            />
                                        </Col>
                                        <Col md={6} className="mb-3">
                                            <input
                                                required
                                                type="number"
                                                className="form-control"
                                                id={`price-${index}`}
                                                name="price"
                                                placeholder="Price (in Rs)"
                                                value={price.price}
                                                onChange={(e) => handlePricingChange(index, e)}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md={6} className="mb-3">
                                            <h6 className="mb-2">New</h6>
                                            <select
                                                required
                                                className="form-select mb-2"
                                                id={`commissionType-${index}`}
                                                name="commissionType"
                                                value={price.commissionType}
                                                onChange={(e) => handlePricingChange(index, e)}
                                            >
                                                <option value="percentage">Percentage</option>
                                                <option value="fixed">Fixed</option>
                                            </select>
                                            <input
                                                required
                                                type="number"
                                                className="form-control mb-2"
                                                id={`commissionValue-${index}`}
                                                name="commissionValue"
                                                placeholder={price.commissionType === "percentage" ? "Commission (%)" : "Commission (Rs)"}
                                                value={price.commissionValue}
                                                onChange={(e) => handlePricingChange(index, e)}
                                            />
                                            <input
                                                type="text"
                                                className="form-control"
                                                readOnly
                                                value={`Commission: ₹${calculateCommissionPrice(price.price, price.commissionValue, price.commissionType)}`}
                                                placeholder="Commission Price"
                                            />
                                        </Col>
                                        <Col md={6} className="mb-3">
                                            <h6 className="mb-2">Renewal</h6>
                                            <select
                                                required
                                                className="form-select mb-2"
                                                id={`renewalCommissionType-${index}`}
                                                name="renewalCommissionType"
                                                value={price.renewalCommissionType}
                                                onChange={(e) => handlePricingChange(index, e)}
                                            >
                                                <option value="percentage">Percentage</option>
                                                <option value="fixed">Fixed</option>
                                            </select>
                                            <input
                                                required
                                                type="number"
                                                className="form-control mb-2"
                                                id={`renewalCommissionValue-${index}`}
                                                name="renewalCommissionValue"
                                                placeholder={price.renewalCommissionType === "percentage" ? "Renewal Commission (%)" : "Renewal Commission (Rs)"}
                                                value={price.renewalCommissionValue}
                                                onChange={(e) => handlePricingChange(index, e)}
                                            />
                                            <input
                                                type="text"
                                                className="form-control"
                                                readOnly
                                                value={`Renewal Commission: ₹${calculateCommissionPrice(price.price, price.renewalCommissionValue, price.renewalCommissionType)}`}
                                                placeholder="Renewal Commission Price"
                                            />
                                        </Col>
                                    </Row>
                                    {index > 0 && (
                                        <div className="text-end mt-3">
                                            <button type="button" className="btn btn-danger btn-sm" onClick={() => handleRemovePricing(index)}>
                                                Delete
                                            </button>
                                        </div>
                                    )}
                                </CardBody>
                            </Card>
                        ))}
                        <div className="mb-4">
                            <button type="button" className="btn btn-secondary" onClick={handleAddPricing}>
                                Add More Pricing
                            </button>
                        </div>
                        <div className="text-end">
                            <button type="submit" className="btn btn-primary">
                                Submit
                            </button>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}

export default connect(null, { setBreadcrumbItems })(CourseAdd)